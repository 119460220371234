// HomePage.tsx
import React, { useCallback, useEffect, useState } from "react";
import "./HomePage.scss";
import goestima from '../../assets/images/goestima-furniture-estimate.png';
import produkt from '../../assets/images/homepage/produkt.png';
import responsive from '../../assets/images/homepage/responsive.png';
import goestimawycenamebli from '../../assets/images/homepage/goestima-wycena-mebli.jpg';
import { useSpring, animated } from 'react-spring';
import { Link } from "react-router-dom";
import { useAuth } from '../../context/AuthContext';
import { TodayIs } from "../../components/TodayIs";
import { Helmet } from "react-helmet";
import axios from "axios";
import config from "../../config";
import { RecentEstimatesList } from "../../components/RecentEstimatesList"; // Dodaj import

interface UserStats {
  estimateCount: number;
  estimateCountThisMonth: number;
  productCount: number;
}

interface Estimate {
  id: number;
  name: string;
  updated_at: string;
}

interface UserData {
  premium_expiration: string | null;
}

export const HomePage: React.FC = () => {
  const { currentUser } = useAuth();
  const [userStats, setUserStats] = useState<UserStats | null>(null);
  const [recentEstimates, setRecentEstimates] = useState<Estimate[]>([]); // Dodaj nowy stan
  const [userData, setUserData] = useState<UserData | null>(null);

  const fetchUserStats = useCallback(async () => {
    if (!currentUser) return;

    try {
      const response = await axios.get<UserStats>(`${config.API_URL}/estimates/user-stats`, {
        headers: {
          'Authorization': `Bearer ${await currentUser.getIdToken()}`
        }
      });
      setUserStats(response.data);
    } catch (error) {
      console.error('Error fetching user stats:', error);
    }
  }, [currentUser]);

  const fetchRecentEstimates = useCallback(async () => {
    if (!currentUser) return;

    try {
      const response = await axios.get<Estimate[]>(`${config.API_URL}/estimates/recent`, {
        headers: {
          'Authorization': `Bearer ${await currentUser.getIdToken()}`
        },
        params: {
          limit: 7
        }
      });
      setRecentEstimates(response.data);
    } catch (error) {
      console.error('Error fetching recent estimates:', error);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      fetchUserStats();
      fetchRecentEstimates();
    }
  }, [currentUser, fetchUserStats, fetchRecentEstimates]);

  const imageProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 }
  });

  const textProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
    delay: 500
  });

  // Dodaj nową funkcję do pobierania danych o premium
  const fetchUserData = useCallback(async () => {
    if (!currentUser) return;

    try {
      const response = await axios.get<UserData>(`/account/users/${currentUser.uid}`, {
        headers: {
          'Authorization': `Bearer ${await currentUser.getIdToken()}`
        }
      });
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, [currentUser]);

  // Dodaj fetchUserData do useEffect
  useEffect(() => {
    if (currentUser) {
      fetchUserData();
      fetchUserStats();
      fetchRecentEstimates();
    }
  }, [currentUser, fetchUserStats, fetchRecentEstimates, fetchUserData]);

  // Dodaj funkcję pomocniczą do sprawdzania statusu premium
  const isPremiumActive = (): boolean => {
    if (!userData?.premium_expiration) return false;
    return new Date(userData.premium_expiration) > new Date();
  };

  const getPremiumExpirationDate = (): string => {
    if (!userData?.premium_expiration) return '';
    return new Date(userData.premium_expiration).toLocaleDateString();
  };

  return (
    <div className="homepage p-4 pb-6">
      <Helmet>
        <title>GoEstima - BEZPŁATNY kalkulator mebli kuchennych dla stolarzy i SMK</title>
        <meta name="description" content="Kalkulator mebli kuchennych | Aplikacja do kompleksowego zarządzania wycenami i umowami | Meble na wymiar." />
        <link rel="canonical" href="https://goestima.pl" />
        <meta property="og:url" content="https://goestima.pl/" />
        <meta property="og:title" content="GoEstima - kalkulator mebli | aplikacja do wycen mebli" />
        <meta property="og:description" content="Kalkulator mebli kuchennych | Aplikacja do kompleksowego zarządzania wycenami i umowami | Meble na wymiar." />
        <meta property="og:image" content="https://goestima.pl/goestima-og.jpg" />
      </Helmet>
      {currentUser ? (
        <>
          <div className="columns">
            <section className="column is-8">
              <div className={`${isPremiumActive() ? '' : 'is-info'} mb-5`}>
                <div>
                  {isPremiumActive() ? (
                    <div>
                      <span className="icon mb-2 has-text-warning">
                        <i className="fas fa-crown"></i>
                      </span>
                      <span className="has-text-weight-bold ml-2">
                        Konto Premium - ważne do {getPremiumExpirationDate()}
                      </span>
                    </div>
                  ) : (
                    <div>
                      <span className="icon mb-2">
                        <i className="fas fa-check"></i>
                      </span>
                      <span className="has-text-weight-bold ml-2">
                        Konto darmowe, przejdź na
                        {' '}
                        <Link
                          to="/premium"
                        >
                          <span>
                            Premium
                          </span>
                          <span className="icon mb-2">
                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                          </span>
                        </Link>
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="content">
                {userStats && (
                  <div>
                    <div>
                      <RecentEstimatesList estimates={recentEstimates} />
                    </div>
                    <div className="columns pt-6 pl-5">
                      {userStats.estimateCount > 0 && (
                        <>
                          <div className="column p-1 pr-5 is-narrow">
                            Ilość wycen:
                            {' '}
                            <strong>{userStats.estimateCount}</strong>
                          </div>
                          <div className="column p-1">
                            W tym miesiącu:
                            {' '}
                            <strong>{userStats.estimateCountThisMonth}</strong>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="pt-5">
                      <h2 className="title is-4 mb-3">Zarządzaj produktami</h2>
                      <p>
                        Możesz edytować ceny produktów systemowych.<br />
                        Możesz również dodawać własne produkty i używać ich do tworzenia wycen.
                      </p>
                      {userStats.productCount > 0 ? (
                        <p className="pl-4 py-3">
                          Własne produkty: <strong>{userStats.productCount}</strong>
                        </p>
                      ) : (
                        <p className="p-3">
                          <strong>Nie dodano jeszcze własnych produktów...</strong>
                        </p>
                      )}
                      <div>
                        <Link
                          to="/products"
                          className="button m-1"
                        >
                          <span className="icon">
                            <i className="fa-solid fa-couch"></i>
                          </span>
                          <span>
                            Edytuj produkty
                          </span>
                        </Link>
                        {' '}
                        <Link
                          to="/addProduct"
                          className="button m-1"
                        >
                          <span className="icon">
                            <i className="fa-solid fa-plus"></i>
                          </span>
                          <span>
                            Dodaj własny produkt
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
            <section className="column is-1">
              {' '}
            </section>
            <section className="column is-hidden-touch is-3">
              <div className="content">
                <h2 className="title is-size-4 has-text-centered has-text-info">
                  <span className="icon">
                    <i className="fa-solid fa-circle-info"></i>
                  </span>
                  {' '}
                  <span>Info</span>
                </h2>
                <span>
                  Cześć
                  {' '}
                  <span className="has-text-info has-text-weight-bold">
                    {currentUser.displayName}
                  </span>!
                </span>
                <TodayIs />
              </div>
              <div className="column is-full">
                <div className="homepage__custom-separator"></div>
              </div>
              <div className="content">
                <h2 className="title is-size-4 has-text-centered has-text-info">
                  <span className="icon">
                    <i className="fa-solid fa-file"></i>
                  </span>
                  {' '}
                  <span>Wzory umów</span>
                </h2>
                <div className="content has-text-centered">
                  <p>
                    Udostępniamy kompleksowy zestaw profesjonalnych wzorów dokumentów, niezbędnych w codziennej pracy stolarza.
                  </p>
                  <Link to="/wzory-umow" className="button is-info">
                    <span className="icon">
                      <i className="fas fa-file-contract"></i>
                    </span>
                    <span>Zobacz wzory</span>
                  </Link>
                </div>
              </div>
              <div className="column is-full">
                <div className="homepage__custom-separator"></div>
              </div>
              <div className="content">
                <h2 className="title is-size-4 has-text-centered has-text-info">
                  <span className="icon">
                    <i className="fa-solid fa-phone"></i>
                  </span>
                  {' '}
                  <span>Kontakt</span>
                </h2>
                <div className="content">
                  <p>Masz pytania / nie wiesz jak zacząć?</p>
                  <p>
                    Tel. <strong>451 261 661</strong><br />
                    Pn. - PT. 15:00 - 20:00
                  </p>
                </div>
                <div>
                  <p>
                    <Link
                      className="has-text-weight-bold"
                      to="/help#faq"
                    >
                      Dział pomocy
                    </Link>
                    <br />
                    <span className="has-text-grey">
                      FAQ, instrukcje, video.
                    </span>
                  </p>
                </div>
              </div>
            </section>
          </div>
        </>
      ) : (
        <div className="is-vcentered">
          <div className="content has-text-centered">
            <h1 className="title is-2 p-6">
              <animated.div style={textProps}>
                Twórz i zarządzaj wycenami mebli na wymiar.<br />
                Z <span className="has-text-info">GoEstima</span>.
              </animated.div>
            </h1>
          </div>
          <div className="content has-text-centered">
            <div className="content has-text-centered">
              <animated.img
                style={{
                  ...imageProps,
                  maxWidth: '550px',
                  width: '100%',
                  height: 'auto'
                }}
                src={goestima}
                alt="Furniture Estimate"
                className="homepage__logo"
              />
            </div>
          </div>
          <div className="column">
            <animated.div style={textProps}>
              <h2 className="title is-3 p-3 has-text-centered">
                GoEstima to prawdopodobnie najlepsza aplikacja do tworzenia <span className="has-text-weight-bold is-underlined">wycen mebli</span>.
              </h2>
            </animated.div>
            <div className="columns pt-5">
              <div className="column is-half is-offset-one-quarter">
                <div className="content is-medium">
                  <p className="has-text-weight-semibold is-size-4 mb-4 has-text-centered">Nasza aplikacja pozwoli Ci:</p>
                  <ul className="has-text-info">
                    <li className="mb-2">Błyskawicznie tworzyć profesjonalne wyceny</li>
                    <li className="mb-2">Efektywnie zarządzać projektami</li>
                    <li className="mb-2">Zwiększyć produktywność i zyski</li>
                  </ul>
                  <p className="mt-5 is-size-5">
                    Z GoEstima proste staje się proste - zamień skomplikowane kalkulacje w intuicyjny proces i skoncentruj się na tym, co potrafisz najlepiej: tworzeniu pięknych mebli.
                  </p>
                  <p className="is-size-5 has-text-weight-semibold">
                    Dołącz do grona zadowolonych meblarzy i przekonaj się, jak GoEstima może zrewolucjonizować Twój biznes!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="content has-text-centered pt-6 pb-5">
            <Link to="/register" className="button is-info is-large is-rounded">
              Rozpocznij za darmo
            </Link>
          </div>

          <div className="content has-text-centered mt-6">
            <h2 className="title is-3">GoEstima działa i wygląda dobrze na każdym urządzeniu.</h2>
            <img
              src={responsive}
              className="pt-3 pb-6"
            />
          </div>

          <div className="content mt-4">
            <h2 className="title is-1 mb-5 has-text-centered">Duża baza gotowych produktów</h2>
            <div className="content">
              <p className="is-size-5 mb-4">
                Aplikacja Goestima oferuje gotową bazę produktów, która obejmuje szeroki zakres kategorii,
                co czyni ją niezwykle wszechstronnym narzędziem do planowania i wyceny mebli oraz wnętrz.
              </p>

              <p className="is-size-5 has-text-weight-semibold mb-3">
                W bazie tej znajdziesz między innymi:
              </p>

              <div className="columns is-multiline title is-4">
                <div className="column is-half">
                  <ul className="has-text-info">
                    <li>Korpusy</li>
                    <li>Blaty</li>
                    <li>Podnośniki</li>
                    <li>Zawiasy</li>
                  </ul>
                </div>
                <div className="column is-half">
                  <ul className="has-text-info">
                    <li>Fronty</li>
                    <li>Szkło</li>
                    <li>Oświetlenie</li>
                    <li>Wiele innych elementów</li>
                  </ul>
                </div>
              </div>
              <p className="is-size-5 mt-4">
                Dzięki temu, korzystając z Goestima, masz dostęp do szerokiego asortymentu produktów,
                co pozwala na łatwe i szybkie tworzenie szczegółowych wycen oraz precyzyjnych planów projektowych.
              </p>
            </div>
          </div>

          <div className="columns mt-6 pb-4 is-vcentered">
            <div className="column has-text-centered">
              <img
                src={produkt}
                alt="Prezentacja produktu w GoEstima"
              />
            </div>
            <div className="column is-two-thirds">
              <h2 className="title is-1 mb-5 has-text-centered">Transparentna wycena przy kliencie</h2>
              <div className="content is-medium">
                <p className="mb-4">
                  GoEstima rewolucjonizuje proces wyceny mebli, umożliwiając tworzenie profesjonalnych ofert bezpośrednio przy kliencie.
                </p>
                <p className="mb-4">
                  Dzięki eleganckiej prezentacji produktów na ekranie, możesz obrócić ekran w stronę klienta i wspólnie przejść przez proces wyceny. To podejście niesie ze sobą liczne korzyści:
                </p>
                <ul className="has-text-info title is-4">
                  <li className="mb-2">Budowanie zaufania poprzez pełną transparentność procesu</li>
                  <li className="mb-2">Natychmiastowe dostosowanie oferty do preferencji klienta</li>
                  <li className="mb-2">Zwiększone zaangażowanie klienta w proces decyzyjny</li>
                  <li className="mb-2">Szybsze podejmowanie decyzji i finalizacja zamówień</li>
                </ul>
                <p className="mt-4 has-text-weight-semibold title is-4">
                  Interaktywna prezentacja produktów w GoEstima nie tylko profesjonalizuje Twoją usługę, ale również znacząco skraca czas potrzebny na przygotowanie i akceptację wyceny.
                </p>
              </div>
            </div>
          </div>

          <div className="content mt-6 is-vcentered">
            <h2 className="title is-1 mb-5 has-text-centered">Elastyczne zarządzanie produktami</h2>
            <div className="columns pt-5">
              <div className="column is-half is-offset-one-quarter">
                <div className="content is-medium">
                  <p className="has-text-weight-semibold is-size-4 mb-4 has-text-centered">
                    GoEstima oferuje niezrównaną elastyczność w zarządzaniu katalogiem produktów, umożliwiając pełną personalizację i kontrolę nad Twoją ofertą.
                  </p>
                  <ul className="has-text-info">
                    <li className="mb-3">Dostosowywanie cen wbudowanych produktów</li>
                    <li className="mb-3">Dodawanie własnych, unikalnych produktów</li>
                    <li className="mb-3">Wszechstronne opcje wyceny (na cm, sztuki, m², auto-kalkulacja)</li>
                    <li className="mb-3">Dodawanie opcji cenowych do produktów</li>
                    <li className="mb-3">Elastyczne zarządzanie widocznością produktów</li>
                  </ul>
                  <p className="has-text-weight-semibold is-size-5 mt-4">
                    Dzięki tym funkcjom, GoEstima dostosowuje się do Twojego unikalnego asortymentu i metod wyceny, zapewniając precyzję i elastyczność w każdym projekcie.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="content pt-6 pb-5">
            <img src={goestimawycenamebli} />
          </div>

          <div className="content mt-6 mb-6 is-vcentered">
            <h2 className="title is-1 mb-4 has-text-centered">Profesjonalna prezentacja i personalizacja</h2>
            <div className="content is-medium">
              <p className="mb-4">
                GoEstima zapewnia nie tylko narzędzia do tworzenia wycen, ale także profesjonalną prezentację wyników i pełną personalizację interfejsu.
              </p>

              <h3 className="subtitle is-4 has-text-weight-semibold mt-5 mb-3">Kluczowe funkcje:</h3>

              <ul className="has-text-info">
                <li className="mb-3">Automatyczny wydruk wyceny</li>
                <li className="mb-3">Generowanie dokumentów w formacie PDF</li>
                <li className="mb-3">Personalizacja z logo Twojej firmy</li>
                <li className="mb-3">Branding w interfejsie aplikacji</li>
              </ul>
              <p className="has-text-weight-semibold is-size-5 mt-4">
                Twoje logo nie tylko pojawi się na wydrukach i plikach PDF, ale również zastąpi logo GoEstima w interfejsie aplikacji, zapewniając spójny wizerunek Twojej marki.
              </p>

              <p className="is-size-5 mt-4">
                Dzięki tym funkcjom, GoEstima staje się nie tylko narzędziem do wycen, ale integralną częścią Twojego profesjonalnego wizerunku, wspierając Cię w budowaniu relacji z klientami.
              </p>
            </div>
          </div>

          <div className="content mt-6 mb-6 is-vcentered">
            <h2 className="title is-1 mb-4 has-text-centered">Profesjonalne wzory umów i dokumentów</h2>
            <div className="columns">
              <div className="column is-8 is-offset-2">
                <div className="content is-medium">
                  <p className="mb-4">
                    Udostępniamy kompleksowy zestaw profesjonalnych wzorów dokumentów, niezbędnych w codziennej pracy stolarza:
                  </p>
                  <ul className="has-text-info">
                    <li className="mb-3">Wzory umów o wykonanie mebli (wersja ogólna i specjalistyczne)</li>
                    <li className="mb-3">Protokoły odbioru mebli</li>
                    <li className="mb-3">Protokoły reklamacyjne</li>
                    <li className="mb-3">Protokoły pomiarowe</li>
                  </ul>
                  <p className="has-text-weight-semibold is-size-5 mt-4">
                    Wszystkie dokumenty są przygotowane przez profesjonalistów i dostosowane do specyfiki branży stolarskiej.
                  </p>
                  <div className="has-text-centered mt-5">
                    <Link to="/wzory-umow" className="button is-info is-medium">
                      <span className="icon">
                        <i className="fas fa-file-contract"></i>
                      </span>
                      <span>Zobacz wzory dokumentów</span>
                    </Link>
                    <p className="mt-3 has-text-grey">
                      * Do pobrania dokumentów wymagane jest posiadanie konta
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h2 className="title is-1 has-text-centered pt-4">
            Docenisz za możliwości.<br />
            Pokochasz za łatwość obsługi.<br />
            <span className="has-text-info">GoEstima</span>.
          </h2>
          <div className="content has-text-centered pt-5 pb-5">
            <Link to="/register" className="button is-info is-large is-rounded">
              Rozpocznij za darmo
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};
