import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import { AdminUser } from '../types';
import { getAuth } from 'firebase/auth';
import debounce from 'lodash/debounce';
import { PremiumEditModal } from "./PremiumEditModal";
import { DeleteUserModal } from "./DeleteUserModal";

interface DeleteOptions {
  deleteFromFirebase: boolean;
}

type SortField = 'premium' | 'totalEstimates' | 'currentMonthEstimates' | 'totalProducts';

export const UsersList: React.FC = () => {
  const [users, setUsers] = useState<AdminUser[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalUsers, setTotalUsers] = useState(0);
  const limit = 10;
  const [selectedUser, setSelectedUser] = useState<AdminUser | null>(null);
  const [userToDelete, setUserToDelete] = useState<AdminUser | null>(null);
  const [sortField, setSortField] = useState<SortField | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  const fetchUsers = useCallback(async (pageNum: number, search: string) => {
    try {
      setLoading(true);
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken();

      const response = await axios.get<{ users: AdminUser[]; total: number }>('/admin/users', {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          page: pageNum,
          limit,
          search: search || undefined,
          sortField: sortField || undefined,
          sortOrder: sortField ? sortOrder : undefined
        }
      });

      if (pageNum === 1) {
        setUsers(response.data.users);
      } else {
        setUsers(prev => [...prev, ...response.data.users]);
      }

      setTotalUsers(response.data.total);
      setHasMore(response.data.users.length === limit);
      setError(null);
    } catch (err) {
      setError('Nie udało się pobrać listy użytkowników');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  }, [limit, sortField, sortOrder]);

  const debouncedSearch = useMemo(
    () => debounce((value: string) => {
      setPage(1);
      fetchUsers(1, value);
    }, 500),
    [fetchUsers]
  );

  useEffect(() => {
    debouncedSearch(searchTerm);
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm, debouncedSearch]);

  useEffect(() => {
    fetchUsers(page, searchTerm);
  }, [page, searchTerm, sortField, sortOrder, fetchUsers]);

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('desc');
    }
    setPage(1);
  };

  const handleDeleteUser = async (firebaseUid: string, deleteOptions: DeleteOptions) => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken();

      await axios.delete(`/admin/users/${firebaseUid}`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          deleteFromFirebase: deleteOptions.deleteFromFirebase
        }
      });

      setPage(1);
      await fetchUsers(1, searchTerm);
    } catch (err) {
      setError('Nie udało się usunąć użytkownika');
      console.error('Error:', err);
    }
  };

  const handlePremiumEdit = async (firebaseUid: string, date: Date | null) => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken();

      await axios.put('/admin/users/premium',
        { firebase_uid: firebaseUid, premium_expiration: date },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      await fetchUsers(page, searchTerm);
    } catch (err) {
      setError('Nie udało się zaktualizować statusu premium');
      console.error('Error:', err);
    }
  };

  const handleLoadMore = () => {
    if (!loading && hasMore) {
      setPage(prev => prev + 1);
    }
  };

  const getSortIcon = (field: SortField) => {
    if (sortField !== field) return <i className="fas fa-sort"></i>;
    return <i className={`fas fa-sort-${sortOrder === 'asc' ? 'up' : 'down'}`}></i>;
  };

  return (
    <div>
      <div className="field mb-5">
        <div className="control has-icons-left">
          <input
            className="input"
            type="text"
            placeholder="Szukaj użytkownika..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <span className="icon is-small is-left">
            <i className="fas fa-search"></i>
          </span>
        </div>
      </div>

      {error && (
        <div className="notification is-danger">{error}</div>
      )}

      <div className="table-container">
        <table className="table is-fullwidth is-striped">
          <thead>
            <tr>
              <th>Email</th>
              <th>Nazwa firmy</th>
              <th
                className="is-clickable"
                onClick={() => handleSort('premium')}
              >
                Status Premium {getSortIcon('premium')}
              </th>
              <th
                className="is-clickable"
                onClick={() => handleSort('totalEstimates')}
              >
                Wyceny (wszystkie) {getSortIcon('totalEstimates')}
              </th>
              <th
                className="is-clickable"
                onClick={() => handleSort('currentMonthEstimates')}
              >
                Wyceny (w tym miesiącu) {getSortIcon('currentMonthEstimates')}
              </th>
              <th
                className="is-clickable"
                onClick={() => handleSort('totalProducts')}
              >
                Własne produkty {getSortIcon('totalProducts')}
              </th>
              <th>Data założenia</th>
              <th>Akcje</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.firebase_uid}>
                <td>{user.email}</td>
                <td>{user.company_name || '-'}</td>
                <td>
                  {user.premium_expiration
                    ? new Date(user.premium_expiration) > new Date()
                      ? `Premium do ${new Date(user.premium_expiration).toLocaleDateString()}`
                      : 'Premium wygasło'
                    : 'Free'
                  }
                </td>
                <td>{user.stats.totalEstimates}</td>
                <td>{user.stats.currentMonthEstimates}</td>
                <td>{user.stats.totalProducts}</td>
                <td>{new Date(user.created_at).toLocaleDateString()}</td>
                <td>
                  <div className="buttons are-small">
                    <button
                      className="button is-link"
                      onClick={() => setSelectedUser(user)}
                    >
                      Edytuj Premium
                    </button>
                    <button
                      className="button is-danger"
                      onClick={() => setUserToDelete(user)}
                    >
                      Usuń
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {loading && (
          <div className="has-text-centered py-4">
            <span className="loader"></span>
          </div>
        )}

        {!loading && hasMore && (
          <div className="has-text-centered py-4">
            <button
              className="button is-primary"
              onClick={handleLoadMore}
              disabled={loading}
            >
              Załaduj więcej
            </button>
          </div>
        )}

        {!loading && !hasMore && users.length > 0 && (
          <div className="has-text-centered py-4 has-text-grey">
            Pokazano wszystkich użytkowników ({totalUsers})
          </div>
        )}

        {!loading && users.length === 0 && (
          <div className="has-text-centered py-4 has-text-grey">
            Brak użytkowników{searchTerm ? ' spełniających kryteria wyszukiwania' : ''}
          </div>
        )}
      </div>

      {selectedUser && (
        <PremiumEditModal
          user={selectedUser}
          isActive={!!selectedUser}
          onClose={() => setSelectedUser(null)}
          onSave={handlePremiumEdit}
        />
      )}
      
      {userToDelete && (
        <DeleteUserModal
          user={userToDelete}
          isActive={!!userToDelete}
          onClose={() => setUserToDelete(null)}
          onConfirm={handleDeleteUser}
        />
      )}
    </div>
  );
};
