/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import "./Premium.scss";
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import { Link } from "react-router-dom";
import przelewy24 from '../../assets/images/Przelewy24_logo.png';
import { P24Response } from '../../types/p24.types';

interface PricingPeriod {
  period: string;
  price: number;
  perMonth: number;
  discount?: number;
  months: number; // Dodajemy to pole
}

interface PricingFeature {
  text: string | ((plan: PricingPlan) => string | JSX.Element);
  included: boolean | ((plan: PricingPlan) => boolean);
}

interface PricingPlan {
  name: string;
  periods: PricingPeriod[];
  description: string;
  features: PricingFeature[];
  buttonText: string;
  isHighlighted?: boolean;
}

interface UserData {
  premium_expiration: string | null;
}

export const Premium: React.FC = () => {
  // Zmiana domyślnego okresu na 12 miesięcy
  const [selectedPeriod, setSelectedPeriod] = useState<string>('12 miesięcy');
  const [userData, setUserData] = useState<UserData | null>(null);
  const { currentUser } = useAuth();
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentUrl,] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const token = await currentUser.getIdToken();
          const response = await axios.get<UserData>(`/account/users/${currentUser.uid}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setUserData(response.data);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [currentUser]);

  const isPremiumActive = (): boolean => {
    if (!userData?.premium_expiration) return false;
    return new Date(userData.premium_expiration) > new Date();
  };

  const getPremiumInfo = (): string => {
    if (!userData?.premium_expiration) return '';
    return `Premium ważne do ${new Date(userData.premium_expiration).toLocaleDateString()}`;
  };

  const features: PricingFeature[] = [
    {
      text: (plan) => plan.isHighlighted ?
        <><strong>Nielimitowana ilość wycen</strong></> :
        <><del>Nielimitowana ilość wycen</del> Max. 2 wyceny /mc</>,
      included: (plan): boolean => Boolean(plan.isHighlighted)
    },
    {
      text: (plan) => plan.isHighlighted ?
        <><strong>Profesjonalne wzory umów o wykonanie mebli</strong></> :
        <><del>Profesjonalne wzory umów o wykonanie mebli</del></>,
      included: (plan): boolean => Boolean(plan.isHighlighted)
    },
    {
      text: (plan) => plan.isHighlighted ?
        <><strong>Wzory protokołów (odbiór, reklamacja)</strong></> :
        <><del>Wzory protokołów (odbiór, reklamacja)</del></>,
      included: (plan): boolean => Boolean(plan.isHighlighted)
    },
    { text: "Zapisywanie wycen", included: true },
    { text: "Drukowanie wycen i PDF", included: true },
    { text: "Udzielanie rabatów", included: true },
    { text: "Zarządzanie zamówieniami", included: true },
    { text: "Dane adresowe klienta", included: true },
    { text: "Notatki do zamówień", included: true },
    { text: "Własne produkty i ceny", included: true },
    { text: "Historia zmian", included: true },
  ];

  const plans: PricingPlan[] = [
    {
      name: "Wersja Bezpłatna",
      periods: [{ period: "na zawsze", price: 0, perMonth: 0, months: 0 }],
      description: "Idealna na start, dostępna od razu po rejestracji.",
      features: features.map(f => ({ ...f })),
      buttonText: "Aktywne konto",
    },
    {
      name: "Wersja Premium",
      periods: [
        { period: "1 miesiąc", price: 49, perMonth: 49, months: 1 },
        { period: "6 miesięcy", price: 264, perMonth: 44, discount: 10, months: 6 },
        { period: "12 miesięcy", price: 470, perMonth: 39, discount: 20, months: 12 },
      ],
      description: "Dla profesjonalistów - nielimitowane możliwości",
      features: features.map(f => ({ ...f })),
      buttonText: "Kup Premium",
      isHighlighted: true,
    }
  ];

  const initializePayment = async (period: PricingPeriod) => {
    if (!currentUser) return;

    setIsProcessing(true);
    console.log("📦 Inicjalizacja płatności dla:", period);

    try {
      const token = await currentUser.getIdToken();

      const url = "/payments/create";
      console.log("📦 Wysyłam żądanie na URL:", url);
      console.log("📦 Pełna konfiguracja axios:", {
        url,
        baseURL: axios.defaults.baseURL,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      const response = await axios.post<P24Response>(
        url,
        {
          months: period.months,
          price: Math.round(period.price) // Upewniamy się że cena jest liczbą całkowitą
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log("✅ Otrzymana odpowiedź z backendu:", response.data);

      if (response.data.redirectUrl) {
        console.log("🔗 Otrzymany link do płatności:", response.data.redirectUrl);
        window.location.href = response.data.redirectUrl;
      } else {
        console.error("🚨 P24 nie zwróciło linka do płatności!");
        alert("Wystąpił problem z płatnością. Spróbuj ponownie.");
      }
    } catch (error) {
      console.error("❌ Błąd płatności:", error);
      if (axios.isAxiosError(error)) {
        console.error("Szczegóły błędu:", error.response?.data);
      }
      alert("Wystąpił błąd podczas inicjowania płatności");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="full-width-page pb-5">
      <div className="section">
        <div className="has-text-centered mb-6">
          <h1 className="title is-2">Wybierz odpowiedni plan</h1>
          <p className="subtitle is-5">
            GoEstima oferuje dwie wersje dostępu do wszystkich funkcji
          </p>
        </div>

        <div className="columns is-centered">
          {plans.map((plan, index) => (
            <div key={index} className="column is-5">
              <div className={`card premium-card ${plan.isHighlighted ? 'highlighted' : ''}`}>
                <div className="card-content">
                  <div className="has-text-centered">
                    <p className="title is-3 premium-title">
                      {plan.name}
                      {plan.isHighlighted && (
                        <i className="fas fa-crown has-text-warning" />
                      )}
                    </p>

                    {plan.isHighlighted && (
                      <div className="period-selector">
                        {plan.periods.map((period, idx) => (
                          <div
                            key={idx}
                            className={`period-option ${selectedPeriod === period.period ? 'active' : ''}`}
                            onClick={() => setSelectedPeriod(period.period)}
                          >
                            {period.period}
                            {period.discount && (
                              <span className="discount-badge">-{period.discount}%</span>
                            )}
                          </div>
                        ))}
                      </div>
                    )}

                    <p className="is-size-1 has-text-weight-bold">
                      {plan.periods.find(p => p.period === selectedPeriod)?.perMonth || 0}
                      <span className="is-size-4"> zł</span>
                      <span className="is-size-5 has-text-grey"> / miesiąc</span>
                    </p>

                    {plan.isHighlighted && (
                      <p className="is-size-6 has-text-grey">
                        łącznie {plan.periods.find(p => p.period === selectedPeriod)?.price} zł za {selectedPeriod}
                      </p>
                    )}

                    <p className="subtitle is-6 mt-5">{plan.description}</p>
                  </div>

                  <div className="content mt-5">
                    <ul className="pricing-features">
                      {plan.features.map((feature, idx) => (
                        <li key={idx}>
                          <span className="icon">
                            <i className={`fas fa-${typeof feature.included === 'function'
                              ? feature.included(plan)
                                ? 'check has-text-success'
                                : 'times has-text-danger'
                              : feature.included
                                ? 'check has-text-success'
                                : 'times has-text-danger'
                            }`} />
                          </span>
                          <span className="feature-text">
                            {typeof feature.text === 'function' ? feature.text(plan) : feature.text}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="has-text-centered mt-4">
                    {plan.isHighlighted ? (
                      <>
                        <button
                          className="button is-medium is-primary"
                          // disabled={!currentUser || isPremiumActive() || isProcessing}
                          disabled
                          onClick={() => {
                            const selectedPeriodData = plan.periods.find(p => p.period === selectedPeriod);
                            if (selectedPeriodData) {
                              initializePayment(selectedPeriodData);
                            }
                          }}
                        >
                          {isProcessing ? (
                            <>
                              <span className="icon">
                                <i className="fas fa-spinner fa-spin"></i>
                              </span>
                              <span>Przetwarzanie...</span>
                            </>
                          ) : (
                            plan.buttonText
                          )}
                        </button>
                        <div className="mt-3">
                          <span className="has-text-danger">Płatności online zostaną aktywowane w najbliższych dniach.</span>
                          <br/><br/>
                          <span className="has-text-weight-bold">Uzyskaj konto Premium teraz - 1 miesiąc GRATIS!</span>
                          <br />
                          Napisz wiadomość SMS na nr tel. 451261661 i podaj adres e-mail na który masz założone bezpłatne konto w GoEstima.
                          
                        </div>
                        {paymentUrl && (
                          <a
                            href={paymentUrl}
                            className="button is-primary mt-3"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Przejdź do płatności
                          </a>
                        )}
                      </>
                    ) : (
                      currentUser ? (
                        <button className="button is-medium is-light" disabled>
                          {plan.buttonText}
                        </button>
                      ) : (
                        <Link to="/register" className="button is-medium">
                          Załóż darmowe konto
                        </Link>
                      )
                    )}
                    {plan.isHighlighted && (
                      <div className="mt-4">
                        {!currentUser ? (
                          <p className="help is-size-5">
                            <Link to="/login">Zaloguj się</Link>
                            {' '} lub {' '}
                            <Link to="/login">załóż darmowe konto</Link>
                            , aby kupić Premium.
                          </p>
                        ) : isPremiumActive() ? (
                          <p className="help has-text-success">
                            {getPremiumInfo()}
                          </p>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="section payment-info-section">
        <div className="payment-operator">
          <p className="is-size-5 has-text-grey mb-4">Bezpieczne płatności obsługuje</p>
          <img
            src={przelewy24}
            alt="PayU - operator płatności"
            width="130"
          />
        </div>

        <div className="premium-important-info">
          <div className="info-item">
            <i className="fas fa-sync-alt has-text-primary"></i>
            <p>To nie jest subskrypcja cykliczna - opłata pobierana jest jednorazowo</p>
          </div>
          <div className="info-item">
            <i className="fas fa-clock has-text-primary"></i>
            <p>Po zakończeniu okresu premium następna opłata nie jest pobierana automatycznie</p>
          </div>
          <div className="info-item">
            <i className="fas fa-shield-alt has-text-primary"></i>
            <p>Wszystkie utworzone wyceny pozostają dostępne również po wygaśnięciu premium</p>
          </div>
        </div>
      </div>
    </div>
  );
};
