import './Header.scss';
import { Logo } from "./Logo";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from '../../context/AuthContext';
import { UserMenu } from "../UserMenu";
import { useUserData } from '../../hooks/useUserData';
import { useWindowSize } from '../../hooks/useWindowSize';
import { UserLogo } from "../UserLogo";

export const Header: React.FC = () => {
  const { currentUser } = useAuth();
  const [userInfo] = useUserData();
  const location = useLocation();
  const { width } = useWindowSize();
  const isMobile = width < 1068;

  const isActive = (path: string): boolean => {
    if (path === '/') {
      return location.pathname === '/';
    }

    if (path === '/products') {
      return location.pathname.startsWith('/products') || location.pathname === '/addProduct';
    }

    const trimmedPath = path.endsWith('/') ? path.slice(0, -1) : path;
    const regex = new RegExp(`^${trimmedPath}(/.*)?$`);
    return regex.test(location.pathname);
  };

  return (
    <header className="header columns is-vcentered">
      <div className="is-narrow header__logo column is-narrow">
        {userInfo?.company_logo ? (
          <UserLogo />
        ) : (
          <Logo />
        )}
      </div>
      <div className="header__center column has-text-centered">
        <div className="columns is-mobile is-centered">
          {currentUser ? (
            <>
              <div className="column is-narrow">
                <Link
                  title="Strona główna"
                  to="/"
                  className={`button ${isActive('/') ? 'is-info' : ''}`}
                >
                  <span className="icon">
                    <i className="fa-solid fa-house"></i>
                  </span>
                </Link>
              </div>
              <div className="column is-narrow">
                <Link
                  title="Nowa wycena"
                  to="/create-estimate"
                  className={`button ${isActive('/create-estimate') ? 'is-primary' : ''}`}
                >
                  <span className="icon">
                    <i className="fa-solid fa-folder-plus"></i>
                  </span>
                  {!isMobile && (
                    <span>
                      Nowa wycena
                    </span>
                  )}
                </Link>
              </div>
              <div className="column is-narrow">
                <Link
                  title="Lista wycen"
                  to="/estimate-furniture"
                  className={`button ${isActive('/estimate-furniture') ? 'is-primary' : ''}`}
                >
                  <span className="icon">
                    <i className="fa-regular fa-folder"></i>
                  </span>
                  {!isMobile && (
                    <span>
                      Lista wycen
                    </span>
                  )}
                </Link>
              </div>
              <div className="column is-narrow">
                <Link
                  title="Zarządzanie produktami"
                  to="/products"
                  className={`button ${isActive('/products') ? 'is-info' : ''}`}
                >
                  <span className="icon">
                    <i className="fa-solid fa-couch"></i>
                  </span>
                </Link>
              </div>
            </>
          ) : (
            <p className="has-text-grey-lighter is-size-7">
              &nbsp;
            </p>
          )}
        </div>
      </div>
      <div className="is-narrow header__right column">
        {currentUser ? (
          <div className="columns is-mobile is-vcentered">
            <div className="column has-text-right">
              <UserMenu />
            </div>
          </div>
        ) : (
          <>
            {/* Desktop view */}
            <div className="columns is-vcentered is-hidden-mobile">
              <div className="column">
                <Link to="/premium">
                  <button className="button">
                    Cennik
                  </button>
                </Link>
              </div>
              <div className="column">
                <Link to="/login">
                  <button className="button">
                    <span className="icon">
                      <i className="fas fa-sign-in-alt"></i>
                    </span>
                    <span>Zaloguj się</span>
                  </button>
                </Link>
              </div>
              <div className="column">
                <Link to="/register">
                  <button className="button is-info">
                    <span className="icon">
                      <i className="fa fa-user-plus"></i>
                    </span>
                    <span>Darmowe konto</span>
                  </button>
                </Link>
              </div>
            </div>

            {/* Mobile view */}
            <div className="is-hidden-tablet">
              <div className="columns is-mobile is-vcentered is-centered">
                <div className="column is-narrow">
                  <Link to="/premium">
                    <button className="button">
                      Cennik
                    </button>
                  </Link>
                </div>
                <div className="column is-narrow">
                  <Link to="/login">
                    <button className="button">
                      <span className="icon">
                        <i className="fas fa-sign-in-alt"></i>
                      </span>
                      <span>Zaloguj się</span>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="columns is-mobile is-vcentered is-centered">
                <div className="column is-narrow">
                  <Link to="/register">
                    <button className="button is-info">
                      <span className="icon">
                        <i className="fa fa-user-plus"></i>
                      </span>
                      <span>Darmowe konto</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </header>
  );
};
