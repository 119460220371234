// src/components/EstimateLimitModal/EstimateLimitModal.tsx
import React from 'react';
import { Link } from 'react-router-dom';

interface EstimateLimitModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const EstimateLimitModal: React.FC<EstimateLimitModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            <span className="icon-text">
              <span className="icon has-text-info">
                <i className="fas fa-info-circle"></i>
              </span>
              <span>Premium wymagane</span>
            </span>
          </p>
          <button className="delete" aria-label="close" onClick={onClose}></button>
        </header>
        <section className="modal-card-body">
          <div className="content">
            <h3 className="title is-5">Odblokuj nielimitowane wyceny z Premium!</h3>
            <p>Wykorzystałeś miesięczny limit wycen w darmowym planie.</p>
            <div className="notification is-info is-light">
              <p className="mb-2"><strong>Z kontem Premium otrzymujesz:</strong></p>
              <p>✓ Nielimitowaną liczbę wycen</p>
              <p>✓ Możliwość tworzenia kopii wycen</p>
              <p>✓ Dostęp do wszystkich funkcji aplikacji</p>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot is-justify-content-center">
          <Link to="/premium" className="button is-primary">
            <span className="icon">
              <i className="fas fa-crown"></i>
            </span>
            <span>Przejdź na Premium</span>
          </Link>
        </footer>
      </div>
    </div>
  );
};
