// src/pages/AdminPanel/components/DeleteUserModal.tsx

import React, { useState } from 'react';
import { AdminUser } from '../types';

interface DeleteUserModalProps {
  user: AdminUser;
  isActive: boolean;
  onClose: () => void;
  onConfirm: (firebaseUid: string, deleteOptions: DeleteOptions) => Promise<void>;
}

interface DeleteOptions {
  deleteFromFirebase: boolean;
}

export const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  user,
  isActive,
  onClose,
  onConfirm
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [deleteFromFirebase, setDeleteFromFirebase] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    setError(null);
    try {
      await onConfirm(user.firebase_uid, { deleteFromFirebase });
      onClose();
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Wystąpił nieoczekiwany błąd');
      }
    } finally {
      setLoading(false);
    }
  };

  const getWarningText = () => {
    if (deleteFromFirebase) {
      return 'Użytkownik zostanie całkowicie usunięty z systemu i Firebase. Nie będzie mógł się ponownie zalogować.';
    }
    return 'Użytkownik zostanie usunięty z systemu, ale jego konto w Firebase pozostanie aktywne.';
  };

  return (
    <div className={`modal ${isActive ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Potwierdź usunięcie użytkownika</p>
          <button className="delete" aria-label="close" onClick={onClose}></button>
        </header>
        <section className="modal-card-body">
          {error && (
            <div className="notification is-danger">
              {error}
            </div>
          )}
          
          <div className="content">
            <p>
              Zamierzasz usunąć użytkownika: <strong>{user.email}</strong>
              {user.company_name && ` (${user.company_name})`}
            </p>

            <div className="block">
              <h6 className="title is-6">Statystyki użytkownika:</h6>
              <ul>
                <li>Liczba wycen: {user.stats.totalEstimates}</li>
                <li>Własne produkty: {user.stats.totalProducts}</li>
              </ul>
            </div>

            <div className="field">
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={deleteFromFirebase}
                  onChange={(e) => setDeleteFromFirebase(e.target.checked)}
                />
                {' '}Usuń również konto użytkownika z Firebase
              </label>
              <p className="help is-warning mt-2">
                {getWarningText()}
              </p>
            </div>

            <div className="notification is-danger is-light mt-4">
              <p><strong>Uwaga!</strong></p>
              <p>Ta operacja spowoduje usunięcie:</p>
              <ul>
                <li>Wszystkich wycen użytkownika</li>
                <li>Wszystkich zamówień</li>
                <li>Własnych produktów</li>
                <li>Wszystkich powiązanych danych</li>
              </ul>
              <p className="mt-2"><strong>Tej operacji nie można cofnąć.</strong></p>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button
            className={`button is-danger ${loading ? 'is-loading' : ''}`}
            onClick={handleConfirm}
          >
            {loading ? 'Usuwanie...' : 'Usuń użytkownika'}
          </button>
          <button 
            className="button" 
            onClick={onClose}
            disabled={loading}
          >
            Anuluj
          </button>
        </footer>
      </div>
    </div>
  );
};
