import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useProductForm } from '../../hooks/useProductForm';
import { toast } from 'react-toastify';
import './AddProductForm.scss';
import defaultImage from '../../assets/images/default_image.jpg';
import { useNavigate } from "react-router-dom";
import { categories } from '../../config/categories';
import { Tooltip } from "../Tooltip";

interface ProductSubmitResult {
  message: string;
  productId: number;
  catChild?: string;
}

export const AddProductForm = () => {
  const { currentUser } = useAuth();
  const [imageUploading, setImageUploading] = useState(false);
  const [uploadedImageName, setUploadedImageName] = useState('');
  const [options, setOptions] = useState<{ option_name: string; selling_price: string; purchase_price: string; }[]>([]);
  const navigate = useNavigate();

  const initialState = {
    unit: '',
    imageSrc: '',
    sellingPrice: '',
    purchasePrice: '',
    extra: false,
    widthAndHeight: false,
    catParent: 'meble',
    catChild: '',
    estimatesId: '',
    title: '',
    description: '',
  };

  const {
    formData,
    handleChange,
    handleFileChange: originalHandleFileChange,
    handleSubmit,
    isSubmitting,
    uploading
  } = useProductForm<ProductSubmitResult>(initialState, (result) => {
    if (result.success && result.data) {
      toast.success('Produkt został dodany!');
      if (result.data.catChild) {
        navigate(`/products/${result.data.catChild}`);
      } else {
        navigate('/products');
      }
    } else {
      toast.error('Nie udało się dodać produktu.');
    }
  });

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 1024 * 1024) {
        toast.error('Plik jest za duży. Maksymalny dozwolony rozmiar to 1 MB.');
        return;
      }
      setImageUploading(true);
      await originalHandleFileChange(event);
      setImageUploading(false);
      setUploadedImageName(file.name);
    }
  };

  const handleOptionChange = (index: number, field: string, value: string | number | null) => {
    if (field === 'selling_price' || field === 'purchase_price') {
      if (typeof value === 'string' && value.includes('.')) {
        toast.error('Proszę używać przecinka przy podawaniu ceny, a nie kropki.');
        return;
      }

      if (typeof value === 'string' && value.includes(',')) {
        const parts = value.split(',');
        if (parts.length > 2 || (parts[1] && parts[1].length > 2)) {
          toast.error('Proszę podać nie więcej niż dwie cyfry po przecinku.');
          return;
        }
      }
    }

    const newOptions = [...options];
    newOptions[index] = { ...newOptions[index], [field]: value };
    setOptions(newOptions);
  };

  const addOption = () => {
    if (options.length < 10) {
      setOptions([...options, { option_name: '', selling_price: '', purchase_price: '' }]);
    } else {
      toast.error('Możesz dodać maksymalnie 10 opcji.');
    }
  };

  const removeOption = (index: number) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
  };

  // const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   const result = await handleSubmit(event, { options });
  //   onSubmitCallback(result);
  // };

  if (!currentUser) {
    return <p>Please log in to add a product.</p>;
  }

  return (
    <form onSubmit={(e) => handleSubmit(e, { options })}>
      <div className="content">
        <h1 className="title has-text-centered">
          <span className="icon has-text-info m-3">
            <i className="fa-regular fa-plus"></i>
          </span>
          <span>
            Nowy produkt
          </span>
        </h1>
        <h2 className="title has-text-centered">
          <span className="has-text-info">
            {formData.title}
          </span>
        </h2>
        <p className="has-text-centered has-text-weight-medium">
          Dodaj własny produkt i używaj go w wycenach.
        </p>
      </div>
      <div className="columns">
        <div className="column is-one-quarter is-narrow has-text-centered">
          <p className="has-text-white box title is-4 p-3 mt-3 has-text-centered has-background-info">
            Obrazek
            {' '}
            <Tooltip
              content="
                Najlepiej będą wyglądały obrazki w proporcji 2:1. Max. rozmiar obrazka to 1 MB.
                "
              iconClassName="fa-regular fa-circle-question"
            />
          </p>
          {imageUploading
            ? <p className="p-4">
              <i className="fas fa-spinner fa-spin"></i> &nbsp; Zapisywanie...
            </p>
            : uploadedImageName
              ? <div className="card"><div className="card-image">
                <figure className="image is-2by1">
                  <img className="addProductForm__image" src={formData.imageSrc} />
                </figure>
              </div></div>
              : <div className="card"><div className="card-image">
                <figure className="image is-2by1">
                  <img className="addProductForm__image" src={defaultImage} />
                </figure>
              </div></div>}

          <label className="file-label addProductForm__label-center mt-5">
            <input
              className="file-input"
              type="file"
              onChange={handleFileChange}
              accept="image/*" />
            <span className="file-cta">
              <span className="file-icon">
                <i className="fas fa-upload"></i>
              </span>
              <span className="file-label">Zmień obrazek</span>
            </span>
          </label>
        </div>
        <div className="column">
          <p className="has-text-white box title is-4 p-3 mt-3 has-text-centered has-background-info">
            Opisy
          </p>
          <div className="field p-1">
            <label className="label">Nazwa <span className="has-text-danger">*</span></label>
            <div className="control has-icons-left has-icons-right">
              <input
                required
                type="text"
                className="input"
                name="title"
                value={formData.title}
                onChange={handleChange}
                placeholder="Nazwa produktu, max. 50 znaków"
              />
              <span className="icon is-small is-left">
                <i className="fa-solid fa-pencil"></i>
              </span>
            </div>
          </div>
          <div className="field p-1">
            <div className="field">
              <label className="label">Opis</label>
              <div className="control has-icons-left has-icons-right">
                <input
                  type="text"
                  className="input"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Krótki opis produktu, max. 50 znaków"
                />
                <span className="icon is-small is-left">
                  <i className="fa-solid fa-pencil"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="field p-1">
            <label className="label">Kategoria <span className="has-text-danger">*</span></label>
            <div className="control has-icons-left">
              <div className="select">
                <select
                  name="catChild"
                  value={formData.catChild}
                  onChange={handleChange}
                >
                  <option value="">Wybierz kategorię</option>
                  {categories.map(category => (
                    <option key={category.catChild} value={category.catChild}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="icon is-small is-left">
                <i className="fa-solid fa-list"></i>
              </div>
            </div>
          </div>
          <div className="field p-1">
            <div className="control">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="extra"
                  checked={formData.extra as boolean}
                  onChange={handleChange}
                />
                {' '}
                To jest dodatek
                <Tooltip
                  content="
                Jeżeli zaznaczysz tę opcję to ten produkt w wycenach będzie wyświetlany w dolnej sekcji DODATKI w swojej kategorii.
                "
                  iconClassName="fa-regular fa-circle-question is-size-6"
                />
              </label>
            </div>
          </div>
        </div>
        <div className="column">
          <p className="has-text-white box title is-4 p-3 mt-3 has-text-centered has-background-info">
            Szczegóły
          </p>
          <div className="field p-1">
            <label className="label">Cena dotyczy <span className="has-text-danger">*</span></label>
            <div className="control has-icons-left">
              <div className="select">
                <select
                  name="unit"
                  value={formData.unit}
                  onChange={handleChange}
                  disabled={formData.widthAndHeight}
                >
                  <option value="">Wybierz jednostkę miary</option>
                  <option value="cm">cm</option>
                  <option value="m2">m2</option>
                  <option value="szt">szt</option>
                  <option value="h">h</option>
                </select>
              </div>
              <div className="icon is-small is-left">
                <i className="fa-solid fa-ruler-combined"></i>
              </div>
            </div>
          </div>
          <div className="field p-1">
            <label className="label">Cena sprzedaży <span className="has-text-danger">*</span></label>
            <div className="control has-icons-left has-icons-right">
              <input
                type="text"
                pattern="^\d+(,\d{1,2})?$"
                className="input"
                name="sellingPrice"
                value={formData.sellingPrice}
                onChange={handleChange}
                placeholder="Wpisz cenę,  np. 19,90"
              />
              <span className="icon is-small is-left">
                <i className="fa-solid fa-coins"></i>
              </span>
              <span className="is-right addProductForm__right-icon">
                zł
                {' '}
                {formData.widthAndHeight ? '/ m2' : formData.unit && `/ ${formData.unit}`}
              </span>
            </div>
          </div>
          <div className="field p-1">
            <label className="label">Cena zakupu <span className="has-text-danger">*</span></label>
            <div className="control has-icons-left has-icons-right">
              <input
                type="text"
                pattern="^\d+(,\d{1,2})?$"
                className="input"
                name="purchasePrice"
                value={formData.purchasePrice}
                onChange={handleChange}
                placeholder="Wpisz cenę,  np. 9,90"
              />
              <span className="icon is-small is-left">
                <i className="fa-solid fa-barcode"></i>
              </span>
              <span className="is-right addProductForm__right-icon">
                zł
                {' '}
                {formData.widthAndHeight ? '/ m2' : formData.unit && `/ ${formData.unit}`}
              </span>
            </div>
          </div>
          <div className="field p-1">
            <div className="control">
              <label className="checkbox">
                <input
                  type="checkbox"
                  name="widthAndHeight"
                  checked={formData.widthAndHeight as boolean}
                  onChange={handleChange}
                />
                {' '}
                Automatyczne obliczanie powierzchni
                <Tooltip
                  content="
                W wycenie będzie można podać szerokość i wysokość. Po włączeniu tej opcji podaj ceny za m2.
                "
                  iconClassName="fa-regular fa-circle-question is-size-6"
                />
              </label>
            </div>
          </div>
          <div className="field p-1 pt-2">
            <label className="label">Opcje produktu
              <Tooltip
                content="
                W wycenie będziesz mógł wybrać dodaną tutaj opcję cenową produktu.
                "
                iconClassName="fa-regular fa-circle-question is-size-6"
              />
            </label>
            {options.map((option, index) => (
              <div key={index}>
                <div className="columns is-vcentered m-1 pb-2">
                  <div className="column">
                    <div className="field">
                      <label className="label is-small">Nazwa opcji</label>
                      <div className="control has-icons-left has-icons-right">
                        <input
                          type="text"
                          placeholder="np. lakier premium"
                          className="input"
                          maxLength={255}
                          value={option.option_name}
                          onChange={(e) => handleOptionChange(index, 'option_name', e.target.value)}
                        />
                        <span className="icon is-small is-left">
                          <i className="fa-solid fa-pencil"></i>
                        </span>
                      </div>
                    </div>
                    <div className="field">
                      <label className="label is-small">Cena sprzedaży</label>
                      <div className="control has-icons-left has-icons-right">
                        <input
                          type="text"
                          className="input"
                          maxLength={10}
                          pattern="^\d+(,\d{1,2})?$"
                          placeholder="Wpisz cenę,  np. 29,90"
                          value={option.selling_price}
                          onChange={(e) => handleOptionChange(index, 'selling_price', e.target.value)}
                        />
                        <span className="icon is-small is-left">
                          <i className="fa-solid fa-coins"></i>
                        </span>
                        <span className="is-right addProductForm__right-icon">
                          zł
                          {' '}
                          {formData.widthAndHeight ? '/ m2' : formData.unit && `/ ${formData.unit}`}
                        </span>
                      </div>
                    </div>
                    <div className="field">
                      <label className="label is-small">Cena zakupu</label>
                      <div className="control has-icons-left has-icons-right">
                        <input
                          type="text"
                          className="input"
                          maxLength={10}
                          pattern="^\d+(,\d{1,2})?$"
                          placeholder="Wpisz cenę,  np. 24,90"
                          value={option.purchase_price}
                          onChange={(e) => handleOptionChange(index, 'purchase_price', e.target.value)}
                        />
                        <span className="icon is-small is-left">
                          <i className="fa-solid fa-barcode"></i>
                        </span>
                        <span className="is-right addProductForm__right-icon">
                          zł
                          {' '}
                          {formData.widthAndHeight ? '/ m2' : formData.unit && `/ ${formData.unit}`}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="column has-text-centered is-narrow">
                    <button type="button" className="button is-danger" onClick={() => removeOption(index)}>
                      <span className="icon">
                        <i className="fas fa-trash-alt"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="content pl-1">
            <button type="button" className="button is-info" onClick={addOption}>
              Dodaj opcję cenową
            </button>
          </div>
        </div>
      </div>
      <div className="has-text-centered pb-6">
        <input type="hidden" name="imageSrc" value={formData.imageSrc} onChange={handleChange} />
        <button
          className="button is-link"
          type="submit"
          disabled={
            isSubmitting
            || uploading
            || !formData.unit
            || !formData.purchasePrice
            || !formData.sellingPrice
            || !formData.title
            || !formData.catChild
          }
        >
          <span className="icon is-small">
            <i className="fa-solid fa-add"></i>
          </span>
          <span>Zapisz nowy produkt</span>
        </button>
      </div>
    </form >
  );
};
