import React, { useState, useEffect, useCallback } from 'react';
import { Products } from '../Products';
import 'bulma/css/bulma.min.css';
import './UserProductTabs.scss';
import { useNavigate } from 'react-router-dom';

interface Tab {
  name: string;
  catChild: string;
}

interface UserProductTabsProps {
  tabs: Tab[];
  catParent: string;
  activeTab: string;
}

export const UserProductTabs: React.FC<UserProductTabsProps> = ({ tabs, catParent, activeTab }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const navigate = useNavigate();

  const handleTabChange = useCallback((catChild: string) => {
    if (activeTab === catChild) {
      setRefreshCount(prevCount => prevCount + 1);
    } else {
      navigate(`/products/${catChild}`);
    }
  }, [activeTab, navigate]);

  useEffect(() => {
    setLoading(false);
  }, [activeTab, refreshCount]);

  return (
    <div className="content">
      <div className="buttons is-centered is-multiline mb-5">
        {tabs.map(tab => (
          <button
            key={tab.catChild}
            className={`button button--fullwidth ${activeTab === tab.catChild ? 'is-info' : ''}`}
            onClick={() => handleTabChange(tab.catChild)}
          >
            {tab.name}
          </button>
        ))}
      </div>
      {loading ? (
        <div className="spinner is-loading"></div>
      ) : (
        <Products
          key={`${activeTab}-${refreshCount}`}
          catParent={catParent}
          catChild={activeTab}
          onLoadingChange={setLoading}
        />
      )}
    </div>
  );
};
