// src/components/DownloadButton/DownloadButton.tsx
import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import config from '../../config';

interface DownloadButtonProps {
  fileId: string;
  buttonLabel?: React.ReactNode;  // Zmiana z string na React.ReactNode
  onNeedLogin: () => void;
  onNeedPremium: () => void;
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({ 
  fileId, 
  buttonLabel = 'Pobierz',
  onNeedLogin,
  onNeedPremium
}) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    if (!currentUser) {
      onNeedLogin();
      return;
    }

    setLoading(true);

    try {
      const token = await currentUser.getIdToken();
      const response = await axios.get<{ url: string }>(
        `${config.API_URL}/account/download/${fileId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Pobieranie pliku bez specyfikowania nazwy
      const link = document.createElement('a');
      link.href = response.data.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('❌ Error fetching download link:', error);
      onNeedPremium();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="download-button">
      <button 
        onClick={handleDownload} 
        disabled={loading} 
        className="button is-link is-fullwidth"
      >
        <span className="icon">
          <i className="fas fa-download"></i>
        </span>
        <span>{loading ? 'Pobieranie...' : buttonLabel}</span>
      </button>
    </div>
  );
};
