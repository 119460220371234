import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import EstimateCategory from '../../components/EstimateCategory/EstimateCategory';
import config from '../../config';
import { useAuth } from '../../context/AuthContext';
import { EstimateSummary } from "../../components/EstimateSummary";
import { Helmet } from "react-helmet";
import { categories } from '../../config/categories';
import { CreateOrderButton } from "../../components/CreateOrderButton";
import { Tooltip } from "../../components/Tooltip";

interface Estimate {
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
  isLocked: boolean;
  id: number;
}

const validTabs = ["total", ...categories.map(cat => cat.catChild)];

export const EstimateDetails: React.FC = () => {
  const { estimateId } = useParams<{ estimateId: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const [estimate, setEstimate] = useState<Estimate | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState<string>("korpusy");
  const [isLoading, setIsLoading] = useState(true);
  const [isLocked, setIsLocked] = useState(false);

  useEffect(() => {
    const path = location.pathname.split('/');
    const tab = path[path.length - 1];

    if (tab && tab !== estimateId && validTabs.includes(tab)) {
      setActiveTab(tab);
    } else {
      setActiveTab("korpusy");
      navigate(`/estimate-furniture/${estimateId}/korpusy`, { replace: true });
    }
  }, [location, estimateId, navigate]);

  const fetchEstimateDetails = useCallback(async () => {
    if (estimateId && currentUser) {
      try {
        const response = await axios.get(`${config.API_URL}/estimates/${estimateId}`, {
          headers: {
            Authorization: `Bearer ${await currentUser.getIdToken()}`
          }
        });
        console.log('Surowa odpowiedź z API:', response.data);

        if (response.data) {
          const fetchedEstimate = {
            ...response.data,
            isLocked: response.data.isLocked === true
          };
          setEstimate(fetchedEstimate);
          setIsLocked(fetchedEstimate.isLocked);
          console.log('Ustawiono isLocked na:', fetchedEstimate.isLocked);
        } else {
          console.error('No data returned for estimate');
          navigate('/estimate-furniture');
        }
      } catch (error) {
        console.error('Error fetching estimate details:', error);
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          console.error('Estimate not found or user has no access.');
          navigate('/estimate-furniture');
        }
      } finally {
        setIsLoading(false);
      }
    }
  }, [estimateId, currentUser, navigate]);

  useEffect(() => {
    fetchEstimateDetails();
  }, [fetchEstimateDetails]);

  const handleChange = (field: 'name' | 'description', value: string) => {
    if (estimate) {
      setEstimate({ ...estimate, [field]: value });
    }
  };

  const handleToggleLock = async () => {
    if (estimateId && currentUser) {
      try {
        const response = await axios.put(`${config.API_URL}/estimates/${estimateId}/toggle-lock`, {}, {
          headers: {
            Authorization: `Bearer ${await currentUser.getIdToken()}`
          }
        });
        console.log('Odpowiedź z toggle lock:', response.data);

        if (response.data.success) {
          const newLockedState = response.data.isLocked === true;
          setIsLocked(newLockedState);
          setEstimate(prev => prev ? { ...prev, isLocked: newLockedState } : null);
          console.log('Zaktualizowano isLocked na:', newLockedState);
        }
      } catch (error) {
        console.error('Error toggling estimate lock:', error);
      }
    }
  };

  const handleSave = async () => {
    if (estimateId && currentUser && estimate) {
      try {
        await axios.put(`${config.API_URL}/estimates/${estimateId}`,
          estimate,
          {
            headers: {
              Authorization: `Bearer ${await currentUser.getIdToken()}`
            }
          }
        );
        setIsEditing(false);
      } catch (error) {
        console.error('Error updating estimate:', error);
      }
    }
  };

  const handleTabClick = (tab: string) => {
    if (validTabs.includes(tab)) {
      setActiveTab(tab);
      navigate(`/estimate-furniture/${estimateId}/${tab}`);
    }
  };

  const refreshEstimateDetails = useCallback(async () => {
    await fetchEstimateDetails();
  }, [fetchEstimateDetails]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!estimate) {
    return <div>No estimate data available.</div>;
  }

  console.log('Stan przed renderowaniem - isLocked:', isLocked, 'estimate:', estimate);

  return (
    <div className="content pb-6">
      <Helmet>
        <title>{`Wycena dla ${estimate.name} | GoEstima`}</title>
        <meta name="description" content="Kalkulator mebli na wymiar" />
        <link rel="canonical" href={`https://goestima.pl/estimate-furniture/${estimate.id}`} />
      </Helmet>

      <div className="columns mt-4 mb-5 is-vcentered">
        <div className="column is-3 m-3 p-5">
          <div className="title is-flex is-align-items-center is-justify-content-center">
            <span className="icon has-text-primary-dark">
              <i className="fa-regular fa-folder-open"></i>
            </span>
            <span className="pl-4 has-text-primary-dark">
              Wycena
            </span>
          </div>
        </div>
        <div className="column box m-3 p-5 has-background-gradient-green">
          <div className="has-text-centered">
            <h1 className="title has-text-white mb-1" style={{ wordBreak: 'break-word' }}>
              <span>{estimate.name}</span>
            </h1>
          </div>

          <div className="has-text-centered has-text-white">
            {estimate.description}
            <div className={`estimate-details-form ${isEditing ? 'is-active pt-6 pb-3' : ''}`}>
              <div className="field">
                <label className="label has-text-white">Nazwa wyceny</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    value={estimate.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                    maxLength={250}
                    placeholder="Jan Kowalski - meble kuchenne"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label has-text-white">Krótki opis wyceny</label>
                <div className="control">
                  <textarea
                    rows={2}
                    className="textarea"
                    value={estimate.description}
                    onChange={(e) => handleChange('description', e.target.value)}
                    maxLength={255}
                    placeholder="ul. Mickiewicza 15, 33-435 Meblowo, tel. 556 667 778"
                  />
                </div>
              </div>
              <button className="button" onClick={handleSave}>Zapisz</button>
            </div>
          </div>
        </div>
        <div className="column is-3 p-3 has-text-centered">
          <>
            <div className="column p-1 buttons--fullwidth">
              <button
                className="button mr-2 button--fullwidth"
                onClick={() => setIsEditing(!isEditing)}
                disabled={isLocked}
              >
                <span className="icon">
                  <i className={`fas ${isEditing ? 'fa-times' : 'fa-edit'}`}></i>
                </span>
                <span>
                  Zmień nazwę
                </span>
              </button>
              {' '}
              <Tooltip
                content="Naciśnij, aby edytować nazwę i opis wyceny."
                iconClassName="fa-regular fa-circle-question"
              />
            </div>
            <div className="column p-1 buttons--fullwidth">
              <button
                className={`button mr-2 button--fullwidth ${isLocked ? 'is-danger' : ''}`}
                onClick={handleToggleLock}
                title={estimate.isLocked ? "Odblokuj wycenę" : "Zablokuj wycenę"}
              >
                <span className="icon-text">
                  <span className="icon">
                    <i className={`fas ${isLocked ? 'fa-lock' : 'fa-lock-open'}`}></i>
                  </span>
                  <span>
                    {isLocked ? 'Blokada włączona' : 'Blokada'}
                  </span>
                </span>
              </button>
              {' '}
              {isLocked ? (
                <Tooltip
                  content="Ochrona przed zmianami w wycenie jest WŁĄCZONA."
                  iconClassName="fa-regular fa-circle-question"
                />
              ) : (
                <Tooltip
                  content="Kliknij, aby włączyć ochronę wyceny przed zmianami."
                  iconClassName="fa-regular fa-circle-question"
                />
              )}
            </div>
            <div className="column p-1 buttons--fullwidth">
              <CreateOrderButton estimateId={estimate.id} />
            </div>
          </>
        </div>
      </div>

      <div className="columns tabs-navigation">
        <div className="column is-narrow">
          <button
            key="total"
            className={`button m-1 has-text-weight-bold ${activeTab === 'total' ? 'is-primary' : ''} sum mr-6`}
            onClick={() => handleTabClick('total')}
          >
            <span className="icon">
              <i className="fas fa-check"></i>
            </span>
            <span>Podsumowanie</span>
          </button>
        </div>
        <div className="column">
          <div className="button-container">
            {categories.map(category => (
              <button
                key={category.catChild}
                className={`button m-1 is-normal ${activeTab === category.catChild ? 'is-dark' : ''}`}
                onClick={() => handleTabClick(category.catChild)}
              >
                <span>{category.name}</span>
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="tab-content">
        {activeTab === "total" && (
          <EstimateSummary
            estimateId={Number(estimateId)}
            estimateName={estimate.name}
            estimateDescription={estimate.description}
            createdAt={estimate.created_at}
            updatedAt={estimate.updated_at}
          />
        )}
        {categories.map(category => (
          activeTab === category.catChild && (
            <EstimateCategory
              key={category.catChild}
              category={category.catChild}
              categoryName={category.name}
              estimateId={Number(estimateId)}
              onProductUpdate={refreshEstimateDetails}
              isLocked={isLocked}
            />
          )
        ))}
      </div>
    </div>
  );
};
