import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const PaymentError: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const errorCode = searchParams.get('error_code');
  const errorMessage = searchParams.get('error_desc');

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      navigate('/premium');
    }, 10000); // 10 sekund przed przekierowaniem

    return () => clearTimeout(redirectTimeout);
  }, [navigate]);

  const getErrorMessage = () => {
    // Mapowanie kodów błędów na przyjazne komunikaty
    switch (errorCode) {
    case 'payment_failed':
      return 'Płatność nie została zrealizowana. Środki nie zostały pobrane z Twojego konta.';
    case 'verification_failed':
      return 'Nie udało się zweryfikować płatności. Jeśli środki zostały pobrane, zostaną zwrócone.';
    case 'session_expired':
      return 'Sesja płatności wygasła. Spróbuj ponownie.';
    case 'technical_error':
      return 'Wystąpił błąd techniczny. Prosimy spróbować później.';
    default:
      return errorMessage || 'Przepraszamy, ale nie udało się zrealizować płatności.';
    }
  };

  return (
    <div className="section">
      <div className="container has-text-centered">
        <div className="box p-6">
          <i className="fas fa-exclamation-circle has-text-danger is-size-1 mb-4"></i>
          <h1 className="title is-3">Wystąpił błąd podczas płatności</h1>
          <p className="subtitle is-5">
            {getErrorMessage()}
          </p>
          {errorCode && (
            <p className="is-size-6 has-text-grey mb-4">
              Kod błędu: {errorCode}
            </p>
          )}
          <div className="buttons is-centered">
            <button
              className="button is-primary mr-2"
              onClick={() => navigate('/premium')}
            >
              Wróć do strony Premium
            </button>
            <a 
              href="mailto:support@goestima.pl" 
              className="button is-light"
            >
              Kontakt z pomocą
            </a>
          </div>
          <p className="mt-4 is-size-7 has-text-grey">
            Za chwilę nastąpi automatyczne przekierowanie do strony Premium
          </p>
        </div>
      </div>
    </div>
  );
};
