// src/pages/AdminPanel/components/PremiumEditModal.tsx

import React, { useState } from 'react';
import { AdminUser } from '../types';

interface PremiumEditModalProps {
  user: AdminUser;
  isActive: boolean;
  onClose: () => void;
  onSave: (firebaseUid: string, date: Date | null) => Promise<void>;
}

export const PremiumEditModal: React.FC<PremiumEditModalProps> = ({
  user,
  isActive,
  onClose,
  onSave
}) => {
  const [date, setDate] = useState<string>(
    user.premium_expiration
      ? new Date(user.premium_expiration).toISOString().split('T')[0]
      : ''
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      await onSave(user.firebase_uid, date ? new Date(date) : null);
      onClose();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setError(error.response?.data?.message || 'Nie udało się zaktualizować statusu premium');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`modal ${isActive ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Edytuj status premium</p>
          <button
            className="delete"
            aria-label="close"
            onClick={onClose}
          ></button>
        </header>
        <form onSubmit={handleSubmit}>
          <section className="modal-card-body">
            {error && (
              <div className="notification is-danger">
                {error}
              </div>
            )}
            <div className="field">
              <label className="label">Email użytkownika</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={user.email}
                  disabled
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Data ważności premium</label>
              <div className="control">
                <input
                  className="input"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
              <p className="help">
                Pozostaw puste aby przełączyć użytkownika na plan FREE
              </p>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button
              className={`button is-success ${loading ? 'is-loading' : ''}`}
              type="submit"
            >
              Zapisz zmiany
            </button>
            <button
              className="button"
              type="button"
              onClick={onClose}
            >
              Anuluj
            </button>
          </footer>
        </form>
      </div>
    </div>
  );
};
