// src/pages/AdminPanel/AdminPanel.tsx

import React from "react";
import "./AdminPanel.scss";
import { Helmet } from "react-helmet";
import { UsersList } from "./components/UsersList";

export const AdminPanel: React.FC = () => {
  return (
    <div className="section">
      <Helmet>
        <title>Panel admina - GoEstima</title>
      </Helmet>
      
      <div className="main">
        <h1 className="title">Panel admina</h1>
        <h2 className="subtitle">Zarządzanie użytkownikami</h2>
        
        <UsersList />
      </div>
    </div>
  );
};
