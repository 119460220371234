import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';

interface PaymentStatus {
  status: string;
  updatedAt: string;
  errorCode?: string;
  errorDesc?: string;
}

export const PaymentSuccess: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isPendingTimeout, setIsPendingTimeout] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const sessionId = searchParams.get('sessionId');
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;
  const PENDING_TIMEOUT = 60000; // 60 sekund

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    let pendingTimeoutId: NodeJS.Timeout;
  
    const checkPaymentStatus = async () => {
      try {
        if (!sessionId) {
          setError('Brak identyfikatora płatności');
          setIsLoading(false);
          return;
        }
  
        if (!currentUser) {
          if (retryCount < MAX_RETRIES) {
            timeoutId = setTimeout(() => {
              setRetryCount(prev => prev + 1);
            }, 2000);
            return;
          } else {
            setError('Nie udało się zweryfikować użytkownika');
            setIsLoading(false);
            return;
          }
        }
  
        const token = await currentUser.getIdToken();
        const response = await axios.get<PaymentStatus>(
          `/payments/status/${sessionId}`,
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );
  
        console.log('Odpowiedź z API:', response.data);
  
        switch (response.data.status) {
        case 'COMPLETED':
          setIsLoading(false);
          setIsPendingTimeout(false);
          setTimeout(() => navigate('/'), 5000);
          break;
            
        case 'PENDING':
          if (!pendingTimeoutId) {
            pendingTimeoutId = setTimeout(() => {
              setIsLoading(false);
              setIsPendingTimeout(true);
              clearTimeout(timeoutId);
            }, PENDING_TIMEOUT);
          }
          if (!isPendingTimeout) {
            timeoutId = setTimeout(checkPaymentStatus, 5000);
          }
          break;
            
        case 'FAILED':
          navigate(`/platnosc/blad?error_code=payment_failed&error_desc=Płatność nie została zrealizowana`);
          break;
            
        case 'CANCELED':
          navigate(`/platnosc/blad?error_code=payment_canceled&error_desc=Płatność została anulowana`);
          break;
            
        case 'ERROR':
          navigate(`/platnosc/blad?error_code=technical_error&error_desc=Wystąpił błąd podczas realizacji płatności`);
          break;
            
        default:
          navigate(`/platnosc/blad?error_code=unknown_error&error_desc=Nieznany status płatności`);
        }
  
      } catch (error) {
        console.error('Błąd sprawdzania statusu:', error);
        setError(error instanceof Error ? error.message : 'Wystąpił nieznany błąd');
        setIsLoading(false);
      }
    };
  
    checkPaymentStatus();
  
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      if (pendingTimeoutId) clearTimeout(pendingTimeoutId);
    };
  }, [sessionId, currentUser, navigate, retryCount, isPendingTimeout]);

  if (error) {
    return (
      <div className="section">
        <div className="container has-text-centered">
          <div className="box p-6">
            <i className="fas fa-exclamation-circle has-text-danger is-size-1 mb-4"></i>
            <h1 className="title is-3">Wystąpił błąd</h1>
            <p className="subtitle is-5">{error}</p>
            <div className="mt-4">
              <button
                className="button is-primary"
                onClick={() => navigate('/premium')}
              >
                Wróć do strony Premium
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="section">
      <div className="container has-text-centered">
        <div className="box p-6">
          {isLoading ? (
            <>
              <i className="fas fa-spinner fa-spin has-text-info is-size-1 mb-4"></i>
              <h1 className="title is-3">Weryfikacja płatności</h1>
              <p className="subtitle is-5">
                Proszę czekać, weryfikujemy status Twojej płatności...
                {!currentUser && retryCount > 0 && (
                  <small className="has-text-grey">Trwa odnawianie sesji... ({retryCount}/{MAX_RETRIES})</small>
                )}
              </p>
              {sessionId && (
                <p className="is-size-7 has-text-grey">
                  ID transakcji: {sessionId}
                </p>
              )}
            </>
          ) : isPendingTimeout ? (
            <>
              <i className="fas fa-clock has-text-warning is-size-1 mb-4"></i>
              <h1 className="title is-3">Płatność w toku</h1>
              <p className="subtitle is-5">
                Twoja płatność jest w trakcie realizacji. Ze względu na wybraną metodę płatności, 
                proces może potrwać do kilku dni roboczych.
              </p>
              <div className="content">
                <p>
                  Po zaksięgowaniu płatności:
                </p>
                <ul className="has-text-left">
                  <li>Otrzymasz potwierdzenie na adres email</li>
                  <li>Twoje konto zostanie automatycznie zaktualizowane o wykupiony okres premium</li>
                </ul>
              </div>
              {sessionId && (
                <p className="is-size-7 has-text-grey mt-4">
                  ID transakcji: {sessionId}
                </p>
              )}
              <button
                className="button is-primary mt-4"
                onClick={() => navigate('/premium')}
              >
                Wróć do strony Premium
              </button>
            </>
          ) : (
            <>
              <i className="fas fa-check-circle has-text-success is-size-1 mb-4"></i>
              <h1 className="title is-3">Dziękujemy za zakup!</h1>
              <p className="subtitle is-5">
                Twoja płatność została zrealizowana pomyślnie.
                Za chwilę zostaniesz przekierowany do strony głównej.
              </p>
              <button
                className="button is-primary"
                onClick={() => navigate('/')}
              >
                Strona główna
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
