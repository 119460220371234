// src/components/AdminRoute.tsx

import React from "react";
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

export const AdminRoute: React.FC = () => {
  const { currentUser, loading } = useAuth();
  const isSuperUser = currentUser?.email === 'spojrzenie@gmail.com';

  if (loading) {
    return <div><span className="loader"></span></div>;
  }

  if (!currentUser || !isSuperUser) {
    // Przekierowanie do strony głównej jeśli użytkownik nie jest adminem
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};
