import React, { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import config from '../../config';
import './CreateEstimate.scss';
import { Helmet } from "react-helmet";

export const CreateEstimate: React.FC = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isPremium, setIsPremium] = useState<boolean>(false);
  const [remainingEstimates, setRemainingEstimates] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // Pobierz informacje o statusie premium i pozostałych wycenach
  useEffect(() => {
    const fetchUserStats = async () => {
      if (!currentUser?.uid) {
        setIsLoading(false);
        return;
      }

      try {
        const token = await currentUser.getIdToken();
        const response = await axios.get(`${config.API_URL}/estimates/user-stats`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        setIsPremium(response.data.isPremium);
        setRemainingEstimates(response.data.remainingThisMonth);
      } catch (error) {
        if (error instanceof AxiosError) {
          console.error('Error fetching user stats:', error.response?.data);
        } else {
          console.error('Unexpected error:', error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserStats();
  }, [currentUser]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser?.uid) {
      alert('Musisz być zalogowany, aby utworzyć wycenę.');
      return;
    }

    try {
      const token = await currentUser.getIdToken();
      const response = await axios.post(`${config.API_URL}/estimates`, {
        name,
        description,
        user_id: currentUser.uid
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const newEstimateId = response.data.estimateId;
      setRemainingEstimates(response.data.remainingThisMonth);
      navigate(`/estimate-furniture/${newEstimateId}`);
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error('Error creating estimate:', error.response?.data);
        if (error.response?.status === 403) {
          if (error.response.data.message?.includes("premium")) {
            alert(`${error.response.data.message}\nPrzejdź do zakładki Premium, aby zwiększyć limit wycen.`);
          } else {
            alert(error.response.data.message);
          }
        } else {
          alert('Nie udało się utworzyć wyceny. Spróbuj ponownie później.');
        }
      } else {
        console.error('Unexpected error:', error);
        alert('Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.');
      }
    }
  };

  if (isLoading) {
    return <div className="has-text-centered py-4">
      <span className="loader"></span>
    </div>;
  }

  return (
    <div className="createEstimate pb-6">
      <Helmet>
        <title>{`Nowa wycena dla ${name} | GoEstima`}</title>
        <meta name="description" content="Tworzenie nowej wyceny. | GoEstima" />
        <link rel="canonical" href="https://goestima.pl/create-estimate" />
      </Helmet>
      <div className="columns is-centered">
        <div className="column is-half m-5">
          <h1 className="title has-text-centered">
            <span className="icon has-text-primary m-3">
              <i className="fa-solid fa-folder-plus"></i>
            </span>
            {name ? (
              <>
                <span>Wycena dla </span>
                <span className="has-text-primary">{name}</span>
              </>
            ) : (
              <span>Nowa wycena</span>
            )}
          </h1>

          {/* Status konta */}
          <div className="mb-5">
            {isPremium ? (
              <div>
              </div>
            ) : remainingEstimates && remainingEstimates > 0 ? (
              <div className="notification is-light">
                <p className="has-text-centered">
                  <strong>Konto Darmowe</strong> -
                  {' '}
                  {
                    remainingEstimates === 1 ? 'pozostała' : 'pozostały'
                  }
                  {' '}
                  <span className="has-text-weight-bold">{remainingEstimates} {
                    remainingEstimates === 1 ? 'wycena' : 'wyceny'
                  }</span> w tym miesiącu.
                </p>
                <p className="has-text-centered mt-5">
                  <Link
                    to="/premium"
                    className="button is-info"
                  >
                    <span>Przejdź na</span>
                    <span className="icon has-text-warning ml-1 mr-1">
                      <i className="fas fa-crown"></i>
                    </span>
                    <span>Premium</span>
                  </Link>
                  <p className="mt-5 has-text-grey">
                    Premium gwarantuje nielimitowaną ilość wycen.
                  </p>
                </p>
              </div>
            ) : (
              <div className="notification is-light">
                <p className="mb-2 has-text-weight-bold has-text-danger has-text-centered">
                  Wykorzystano limit wycen w tym miesiącu.
                </p>
                <p className="has-text-centered mt-5">
                  <Link
                    to="/premium"
                    className="button is-info"
                  >
                    <span>Przejdź na</span>
                    <span className="icon has-text-warning ml-1 mr-1">
                      <i className="fas fa-crown"></i>
                    </span>
                    <span>Premium</span>
                  </Link>
                  <p className="mt-5 has-text-grey">
                    Premium gwarantuje nielimitowaną ilość wycen.
                  </p>
                </p>
              </div>
            )}
          </div>

          <form onSubmit={handleSubmit}>
            <div className="field">
              <label className="label">
                Nazwa wyceny <span className="has-text-danger">*</span>
              </label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  maxLength={250}
                  placeholder="Jan Kowalski (pole wymagane)"
                  disabled={!isPremium && remainingEstimates === 0}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Opis wyceny</label>
              <div className="control">
                <textarea
                  className="textarea"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  maxLength={1024}
                  placeholder="Wycena mebli kuchennych"
                  rows={4}
                  disabled={!isPremium && remainingEstimates === 0}
                />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button
                  type="submit"
                  className="button is-primary is-fullwidth"
                  disabled={(!isPremium && remainingEstimates === 0) || !name.trim()}
                >
                  Utwórz wycenę
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
