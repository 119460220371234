import React, { useEffect } from "react";
import "./Products.scss";
import { UserProductTabs } from "../../components/UserProductTabs";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { categories } from '../../config/categories';

export const Products: React.FC = () => {
  const { category } = useParams<{ category: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!category) {
      navigate(`/products/${categories[0].catChild}`, { replace: true });
    } else if (!categories.some(cat => cat.catChild === category)) {
      navigate(`/products/${categories[0].catChild}`, { replace: true });
    }
  }, [category, navigate]);

  if (!category) {
    return null;
  }

  return (
    <div className="main pb-6">
      <Helmet>
        <title>{`Edycja produktów | GoEstima`}</title>
        <meta name="description" content="Tutaj możesz edytować produkty systemowe i/lub dodać własne produkty. | GoEstima" />
        <link rel="canonical" href={`https://goestima.pl/products/${category}`} />
      </Helmet>
      <div className="has-text-centered pb-5">
        <h1 className="title">
          <span className="icon has-text-info m-3">
            <i className="fa-solid fa-couch"></i>
          </span>
          {' '}
          <span>Lista produktów</span>
        </h1>
        <p className="subtitle is-4">do tworzenia wycen</p>
      </div>
      <div className="content has-text-centered p-4">
        <Link to="/addProduct" className="button is-info">
          <span className="icon">
            <i className="fa-solid fa-plus"></i>
          </span>
          <span>Dodaj produkt</span>
        </Link>
      </div>
      <div className="content">
        <UserProductTabs catParent="meble" tabs={categories} activeTab={category} />
      </div>
    </div>
  );
};
