import React, { useState } from "react";
import "./Files.scss";
import { Helmet } from "react-helmet";
import { DownloadButton } from "../../components/DownloadButton";
import { FileDownloadModal } from "../../components/FileDownloadModal";

interface FileCardProps {
  imageSrc: string;
  title: string;
  description: string;
  fileIds: {
    pdf: string;
    doc: string;
  };
  onNeedLogin: () => void;
  onNeedPremium: () => void;
}

const FileCard: React.FC<FileCardProps> = ({
  imageSrc,
  title,
  description,
  fileIds,
  onNeedLogin,
  onNeedPremium,
}) => (
  <div className="column is-half-tablet is-one-third-desktop">
    <div className="card files-card">
      <div className="card-image">
        <figure className="files-image is-3by2">
          <img src={imageSrc} alt={title} />
        </figure>
      </div>
      <div className="card-content">
        <h3 className="title is-5">{title}</h3>
        <div className="content">
          <p>{description}</p>
        </div>
        <div className="buttons files_buttons files_buttons__button has-addons is-centered is-justify-content-space-between">
          <DownloadButton
            fileId={fileIds.pdf}
            buttonLabel={
              <span className="icon-text">
                <span className="icon">
                  <i className="fas fa-file-pdf"></i>
                </span>
                <span>PDF</span>
              </span>
            }
            onNeedLogin={onNeedLogin}
            onNeedPremium={onNeedPremium}
          />
          <DownloadButton
            fileId={fileIds.doc}
            buttonLabel={
              <span className="icon-text">
                <span className="icon">
                  <i className="fas fa-file-word"></i>
                </span>
                <span>DOC (edytowalny)</span>
              </span>
            }
            onNeedLogin={onNeedLogin}
            onNeedPremium={onNeedPremium}
          />
        </div>
      </div>
    </div>
  </div>
);

interface Document {
  imageSrc: string;
  title: string;
  description: string;
  fileIds: {
    pdf: string;
    doc: string;
  };
}

export const Files: React.FC = () => {
  const [modalType, setModalType] = useState<'login' | 'premium' | null>(null);

  const documents: Document[] = [
    {
      imageSrc: "/images/wzory-umow/UMOWA-o-wykonanie-mebli-OGOLNA-GoEstima.png",
      title: "Umowa o wykonanie mebli - wersja ogólna",
      description: "Profesjonalny wzór umowy dla każdego projektu meblowego. Zawiera wszystkie niezbędne zapisy prawne i zabezpieczenia dla stolarza i klienta.",
      fileIds: {
        pdf: "UMOWA-o-wykonanie-mebli-OGOLNA-GoEstima.pdf",
        doc: "UMOWA-o-wykonanie-mebli-OGOLNA-GoEstima.odt"
      }
    },
    {
      imageSrc: "/images/wzory-umow/UMOWA-o-wykonanie-mebli-KUCHNIE-GoEstima.png",
      title: "Umowa - meble kuchenne",
      description: "Specjalistyczna umowa dedykowana dla projektów kuchennych. Uwzględnia specyfikę mebli kuchennych, montażu AGD i instalacji.",
      fileIds: {
        pdf: "UMOWA-o-wykonanie-mebli-KUCHNIE-GoEstima.pdf",
        doc: "UMOWA-o-wykonanie-mebli-KUCHNIE-GoEstima.odt"
      }
    },
    {
      imageSrc: "/images/wzory-umow/UMOWA-o-wykonanie-mebli-SZAFY-GoEstima.png",
      title: "Umowa - szafa na wymiar",
      description: "Wzór umowy dostosowany do realizacji szaf wnękowych i garderób. Zawiera szczegółowe zapisy o pomiarach i zabudowie.",
      fileIds: {
        pdf: "UMOWA-o-wykonanie-mebli-SZAFY-GoEstima.pdf",
        doc: "UMOWA-o-wykonanie-mebli-SZAFY-GoEstima.odt"
      }
    },
    {
      imageSrc: "/images/wzory-umow/PROTOKOL-ODBIORU-GoEstima.png",
      title: "Protokół odbioru mebli",
      description: "Profesjonalny protokół odbioru mebli. Zabezpiecza interesy obu stron i potwierdza prawidłowe wykonanie zlecenia oraz stan mebli.",
      fileIds: {
        pdf: "PROTOKOL-ODBIORU-GoEstima.pdf",
        doc: "PROTOKOL-ODBIORU-GoEstima.odt"
      }
    },
    {
      imageSrc: "/images/wzory-umow/PROTOKOL-REKLAMACYJNY-GoEstima.png",
      title: "Protokół reklamacyjny",
      description: "Kompleksowy wzór protokołu reklamacyjnego. Pomaga w sprawnym procesie obsługi reklamacji i dokumentacji usterek.",
      fileIds: {
        pdf: "PROTOKOL-REKLAMACYJNY-GoEstima.pdf",
        doc: "PROTOKOL-REKLAMACYJNY-GoEstima.odt"
      }
    },
    {
      imageSrc: "/images/wzory-umow/PROTOKOL-POMIAROWY-GoEstima.png",
      title: "Protokół pomiarowy",
      description: "Profesjonalny dokument do zapisu wymiarów i ustaleń technicznych. Zmniejsza ryzyko błędów wykonawczych i nieporozumień.",
      fileIds: {
        pdf: "PROTOKOL-POMIAROWY-GoEstima.pdf",
        doc: "PROTOKOL-POMIAROWY-GoEstima.odt"
      }
    }
  ];

  return (
    <div className="section pt-5 files-page">
      <Helmet>
        <title>Wzory umów i dokumentów dla stolarza | GoEstima</title>
        <meta
          name="description"
          content="Profesjonalne wzory umów o wykonanie mebli, protokoły odbioru, dokumenty reklamacyjne i więcej. Zabezpiecz swoją działalność z GoEstima!"
        />
        <link rel="canonical" href="https://goestima.pl/wzory-umow" />
      </Helmet>

      {/* Mobile document icon header */}
      <div className="is-hidden-tablet has-text-centered mb-5">
        <i className="fas fa-file-contract fa-4x has-text-primary d-block mb-4"></i>
        <h2 className="title is-4">
          Profesjonalne wzory dokumentów
        </h2>
      </div>

      {/* Desktop document icon header */}
      <h2 className="title is-3 mb-4 has-text-centered is-hidden-mobile">
        <span className="icon-text is-justify-content-center">
          <span className="icon mr-3">
            <i className="fas fa-file-contract has-text-primary"></i>
          </span>
          <span>Profesjonalne wzory dokumentów</span>
        </span>
      </h2>

      <div className="container mb-6">
        <div className="content mb-6">
          <div className="notification files-notification is-info is-light features-section mb-6">
            <div className="columns is-vcentered">
              <div className="column">
                <p className="is-size-4 has-text-centered mb-5">
                  <strong>Zabezpiecz swoją działalność!</strong> Skorzystaj z profesjonalnie
                  przygotowanych wzorów dokumentów dla stolarzy.
                </p>

                <div className="columns is-multiline is-mobile files-benefits-grid">
                  <div className="column is-6-mobile is-3-tablet">
                    <div className="files-benefit-item">
                      <span className="files-icon-text">
                        <span className="icon has-text-success">
                          <i className="fas fa-check"></i>
                        </span>
                        <span>Zgodne z prawem</span>
                      </span>
                    </div>
                  </div>
                  <div className="column is-6-mobile is-3-tablet">
                    <div className="files-benefit-item">
                      <span className="icon-text">
                        <span className="icon has-text-success">
                          <i className="fas fa-check"></i>
                        </span>
                        <span>Sprawdzone w praktyce</span>
                      </span>
                    </div>
                  </div>
                  <div className="column is-6-mobile is-3-tablet">
                    <div className="files-benefit-item">
                      <span className="icon-text">
                        <span className="icon has-text-success">
                          <i className="fas fa-check"></i>
                        </span>
                        <span>Łatwe do edycji</span>
                      </span>
                    </div>
                  </div>
                  <div className="column is-6-mobile is-3-tablet">
                    <div className="files-benefit-item">
                      <span className="icon-text">
                        <span className="icon has-text-success">
                          <i className="fas fa-check"></i>
                        </span>
                        <span>Chronią obie strony</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="column is-narrow is-hidden-mobile">
                <span className="icon is-large files-shield-icon">
                  <i className="fas fa-shield-alt fa-3x has-text-info"></i>
                </span>
              </div>
            </div>
          </div>

          {/* Nowa sekcja */}
          <div className="block mb-6">
            <div className="columns is-multiline">
              <div className="column is-12 mb-5">
                <h3 className="title is-4 mb-3">Dlaczego warto korzystać z profesjonalnych wzorów?</h3>
                <p className="mb-4">
                  Prowadzenie działalności stolarskiej wiąże się z wieloma wyzwaniami prawnymi.
                  Odpowiednio przygotowane dokumenty to podstawa bezpiecznego prowadzenia biznesu
                  i budowania profesjonalnych relacji z klientami.
                </p>
                <p>
                  Nasze wzory zostały opracowane we współpracy z profesjonalistami specjalizującymi się
                  w prawie gospodarczym i uwzględniają specyfikę branży stolarskiej.
                </p>
              </div>

              <div className="column is-12 is-6-desktop mb-0">
                <h4 className="title is-5 mb-3">Co zyskujesz?</h4>
                <ul className="ml-5">
                  <li className="mb-2">Oszczędność czasu - nie musisz tworzyć dokumentów od zera</li>
                  <li className="mb-2">Bezpieczeństwo prawne - wszystkie dokumenty są zgodne z aktualnym prawem</li>
                  <li className="mb-2">Profesjonalny wizerunek - pokazujesz klientom, że działasz kompleksowo</li>
                  <li>Minimalizacja ryzyka - zabezpieczasz się przed niejasnymi sytuacjami</li>
                </ul>
              </div>

              <div className="column is-12 is-6-desktop mb-5">
                <h4 className="title is-5 mb-3">Jak to działa?</h4>
                <ul className="ml-5">
                  <li className="mb-2">Wybierz potrzebny dokument z listy poniżej</li>
                  <li className="mb-2">Pobierz w formacie PDF do wydruku lub DOC do edycji</li>
                  <li className="mb-2">Dostosuj treść do swoich potrzeb (wersja DOC)</li>
                  <li>Używaj w swojej codziennej pracy!</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="columns is-multiline p-3">
            {documents.map((doc, index) => (
              <FileCard
                key={index}
                {...doc}
                onNeedLogin={() => setModalType('login')}
                onNeedPremium={() => setModalType('premium')}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="main">
        <div className="content has-text-grey is-size-7 has-text-centered mt-6 pt-6 disclaimer-text">
          <p>
            Przedstawione wzory dokumentów mają charakter wyłącznie informacyjny i pomocniczy.
            GoEstima nie ponosi odpowiedzialności za skutki prawne wynikające z ich wykorzystania.
            Zalecamy dostosowanie dokumentów do indywidualnych potrzeb i konsultację z prawnikiem.
          </p>
        </div>
      </div>

      <FileDownloadModal
        isOpen={modalType !== null}
        onClose={() => setModalType(null)}
        type={modalType || 'login'}
      />
    </div>
  );
};
