import React, { useState } from 'react';
import useManageUserData from '../../hooks/useManageUserData'; // Zaimportuj hook
import { UserData } from '../../types/UserData';
import { EditMode } from "../EditMode";
import { useEditMode } from '../../context/EditModeContext';
import './UserInfo.scss';
import { toast } from 'react-toastify';
import defaultLogo from '../../assets/images/default_logo.png';

interface UserInfoComponentProps {
  userInfo: UserData;
  onUpdate: () => void;
}

interface InputFieldProps {
  type: string;
  name: string;
  value: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly: boolean;
}

const InputField: React.FC<InputFieldProps> = ({ type, name, value, onChange, readOnly }) => (
  <input
    type={type}
    name={name}
    value={value || ''}
    onChange={onChange}
    readOnly={readOnly}
    className={`input ${readOnly ? 'user-info__readonly' : ''}`}
  />
);

export const UserInfo: React.FC<UserInfoComponentProps> = ({ userInfo, onUpdate }) => {
  const {
    formData,
    isLoading,
    editMode,
    setEditMode,
    handleInputChange,
    handleSelectChange,
    saveUserData,
    uploadLogo,
    handleDirectUpdate,
    updateTooltipSetting // Dodaj to
  } = useManageUserData(userInfo);

  const [isTooltipUpdating, setIsTooltipUpdating] = useState(false);
  const { isEditModeEnabled } = useEditMode();

  const handleSave = async () => {
    await saveUserData();
    onUpdate();
  };

  const handleRemoveLogo = () => {
    // Aktualizacja lokalnego stanu formData, bez automatycznego zapisywania
    handleDirectUpdate('company_logo', '');

    // Informowanie użytkownika, że logo zostało usunięte lokalnie i wymaga zapisania
    toast.info("Logo zostało usunięte. Kliknij 'Zapisz', aby potwierdzić zmianę.");
  };

  const handleTooltipChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked;
    setIsTooltipUpdating(true);
    try {
      await updateTooltipSetting(newValue);
      toast.success(newValue ? 'Podpowiedzi zostały włączone' : 'Podpowiedzi zostały wyłączone');
    } catch (error) {
      toast.error('Nie udało się zmienić ustawień podpowiedzi');
    } finally {
      setIsTooltipUpdating(false);
    }
  };

  return (
    <>
      <div className="columns is-multiline">
        <div className="column is-full-mobile is-half-tablet">
          <div className="box has-text-centered">
            <div className="field">
              <label className="label is-size-4">
                <span className="icon">
                  <i className="fa-regular fa-circle-question"></i>
                </span>
                {' '}
                <span>Ustawienia podpowiedzi</span>
              </label>
              <div className="control">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={formData.tooltips_enabled}
                    onChange={handleTooltipChange}
                    disabled={isTooltipUpdating}
                  />
                  <span className="slider round"></span>
                </label>
                <span className="ml-2">
                  {formData.tooltips_enabled ? 'Podpowiedzi są włączone' : 'Podpowiedzi są wyłączone'}
                </span>
              </div>
              <p className="help">
                Włącz lub wyłącz podpowiedzi
                <span className="icon">
                  <i className="fa-regular fa-circle-question"></i>
                </span>
                w aplikacji. Zmiana nastąpi natychmiast.
              </p>
            </div>
            {isTooltipUpdating && <p className="has-text-info">Aktualizowanie ustawień...</p>}
          </div>
        </div>

        <div className="column is-full-mobile is-half-tablet">
          <div className="box has-text-centered">
            <div className="field">
              <label className="label is-size-4">
                <span className="icon">
                  <i className="fa-solid fa-pencil"></i>
                </span>
                {' '}
                <span>
                  Ustawienia trybu edycji
                </span>
              </label>
              <div className="control">
                <EditMode />
                <span className="ml-2">
                  {isEditModeEnabled ? 'Tryb edycji jest włączony' : 'Tryb edycji jest wyłączony'}
                </span>
              </div>
              <p className="help">
                Aktywuje przycisk edycji produktu na wycenie. Ta opcja wyłącza się po zamknięciu aplikacji.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 mb-6">
        <label className="label is-size-4">Twoje dane</label>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">Imię</label>
              <div className="control">
                <InputField type="text" name="first_name" value={formData.first_name} onChange={editMode ? handleInputChange : undefined} readOnly={!editMode} />
              </div>
            </div>
            <div className="field">
              <label className="label">Nazwisko</label>
              <div className="control">
                <InputField type="text" name="last_name" value={formData.last_name || ''} onChange={editMode ? handleInputChange : undefined} readOnly={!editMode} />
              </div>
            </div>
            <div className="field">
              <label className="label">E-mail</label>
              <div className="control">
                <InputField type="email" name="email" value={formData.email} onChange={editMode ? handleInputChange : undefined} readOnly />
              </div>
            </div>
            <div className="field">
              <label className="label">Nr telefonu</label>
              <div className="control">
                <InputField type="number" name="phone_number" value={formData.phone_number || ''} onChange={editMode ? handleInputChange : undefined} readOnly={!editMode} />
              </div>
            </div>
            <div className="field">
              <label className="label">Kraj</label>
              <div className="control">
                <div className="select">
                  <select
                    name="country"
                    value={formData.country || ''}
                    onChange={editMode ? handleSelectChange : undefined}
                    disabled={!editMode}
                  >
                    <option value="">Wybierz kraj</option>
                    <option value="PL">Polska</option>
                    <option value="AT">Austria</option>
                    <option value="BE">Belgia</option>
                    <option value="BG">Bułgaria</option>
                    <option value="HR">Chorwacja</option>
                    <option value="CY">Cypr</option>
                    <option value="CZ">Czechy</option>
                    <option value="DK">Dania</option>
                    <option value="EE">Estonia</option>
                    <option value="FI">Finlandia</option>
                    <option value="FR">Francja</option>
                    <option value="GR">Grecja</option>
                    <option value="ES">Hiszpania</option>
                    <option value="NL">Holandia</option>
                    <option value="IE">Irlandia</option>
                    <option value="LT">Litwa</option>
                    <option value="LU">Luksemburg</option>
                    <option value="LV">Łotwa</option>
                    <option value="MT">Malta</option>
                    <option value="DE">Niemcy</option>
                    <option value="PT">Portugalia</option>
                    <option value="CZ">Republika Czeska</option>
                    <option value="RO">Rumunia</option>
                    <option value="SK">Słowacja</option>
                    <option value="SI">Słowenia</option>
                    <option value="SE">Szwecja</option>
                    <option value="HU">Węgry</option>
                    <option value="IT">Włochy</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="field">
              <label className="label">Nazwa firmy</label>
              <div className="control">
                <InputField type="text" name="company_name" value={formData.company_name || ''} onChange={editMode ? handleInputChange : undefined} readOnly={!editMode} />
              </div>
            </div>
            <div className="field">
              <label className="label">NIP</label>
              <div className="control">
                <InputField type="number" name="company_nip" value={formData.company_nip || ''} onChange={editMode ? handleInputChange : undefined} readOnly={!editMode} />
              </div>
            </div>
            <div className="field">
              <label className="label">Ulica i nr domu</label>
              <div className="control">
                <InputField type="text" name="address_line" value={formData.address_line || ''} onChange={editMode ? handleInputChange : undefined} readOnly={!editMode} />
              </div>
            </div>
            <div className="field">
              <label className="label">Kod pocztowy</label>
              <div className="control is-one-third">
                <InputField type="text" name="zipcode_postal" value={formData.zipcode_postal || ''} onChange={editMode ? handleInputChange : undefined} readOnly={!editMode} />
              </div>
            </div>
            <div className="field">
              <label className="label">Miasto</label>
              <div className="control is-one-third">
                <InputField type="text" name="city" value={formData.city || ''} onChange={editMode ? handleInputChange : undefined} readOnly={!editMode} />
              </div>
            </div>
            <InputField type="hidden" name="company_logo" value={formData.company_logo || ''} onChange={editMode ? handleInputChange : undefined} readOnly={!editMode} />
          </div>

          <div className="column">
            <div className="column has-text-centered pb-5">
              {formData.company_logo && (
                <div>
                  <img src={formData.company_logo} alt="Company Logo" />
                </div>
              )}
              {editMode && (
                <div className="column">
                  {formData.company_logo && (
                    <button className="button is-danger is-small" onClick={handleRemoveLogo}>
                      Usuń logo
                    </button>
                  )}
                  <div className="file is-boxed pt-5 is-centered">
                    <label className="file-label">
                      <input
                        className="file-input"
                        type="file"
                        onChange={e => {
                          const files = e.target.files;
                          if (files && files.length > 0) {
                            const file = files[0];
                            if (file.size > 1024 * 1024) { // 1 MB
                              toast.error('Plik jest za duży. Maksymalny dozwolony rozmiar to 1 MB.');
                            } else {
                              uploadLogo(file);
                            }
                          }
                        }}
                      />
                      <span className="file-cta">
                        <span className="file-icon">
                          <i className="fas fa-upload"></i>
                        </span>
                        <span className="file-label">Zmień logo</span>
                      </span>
                    </label>
                    {isLoading && <p><span className="loader"></span></p>}
                  </div>
                </div>
              )}
              {!formData.company_logo && !editMode && (
                <>
                  <p><img src={defaultLogo} /></p>
                  <p>Brak logo - edytuj formularz aby ustawić własne logo.</p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="has-text-centered">
          {editMode ? (
            isLoading ? (
              <button className="button is-danger" type="button" disabled>
                <i className="fas fa-spinner fa-spin"></i> &nbsp; Zapisywanie...
              </button>
            ) : (
              <button className="button is-danger" type="button" onClick={handleSave}>
                Zapisz
              </button>
            )
          ) : (
            <button className="button is-link" type="button" onClick={() => setEditMode(true)}>Edytuj</button>
          )}
        </div>
      </div>
    </>
  );
};
