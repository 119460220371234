import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { articleList, ArticleInfo } from "./articleList";

interface Section {
  id: string;
  title: string;
}

export const Art02: React.FC = () => {
  const [sections, setSections] = useState<Section[]>([]);

  useEffect(() => {
    const headings = document.querySelectorAll('h2');
    const newSections = Array.from(headings).map((heading, index) => ({
      id: `section-${index}`,
      title: heading.textContent || `Section ${index + 1}`,
    }));
    setSections(newSections);

    headings.forEach((heading, index) => {
      heading.id = `section-${index}`;
    });
  }, []);

  return (
    <article className="content">
      <Helmet>
        <title>Jak zaplanować i zrealizować kuchnię na wymiar: Krok po kroku | GoEstima</title>
        <meta name="description" content="Dowiedz się, jak krok po kroku zaplanować i zrealizować kuchnię na wymiar. Od analizy potrzeb, przez projekt, po wybór wykonawcy - kompleksowy przewodnik." />
        <link rel="canonical" href="https://goestima.pl/articles/jak-zaplanowac-kuchnie-na-wymiar" />
        <meta property="og:url" content="https://goestima.pl/articles/jak-zaplanowac-kuchnie-na-wymiar" />
        <meta property="og:title" content="Jak zaplanować i zrealizować kuchnię na wymiar: Krok po kroku | GoEstima" />
        <meta property="og:description" content="Dowiedz się, jak krok po kroku zaplanować i zrealizować kuchnię na wymiar. Od analizy potrzeb, przez projekt, po wybór wykonawcy - kompleksowy przewodnik." />
        <meta property="og:image" content="https://goestima.pl/articles-images/planowanie-kuchni.jpg" />
      </Helmet>

      <div className="content pb-6">
        <div className="columns is-centered">
          <div className="column is-one-quarter">
            <div className="box">
              <h3 className="title is-4">Spis treści</h3>
              <ul>
                {sections.map((section) => (
                  <li key={section.id}>
                    <a href={`#${section.id}`}>{section.title}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="box mt-4">
              <h3 className="title is-4">Wszystkie artykuły</h3>
              <ul>
                {articleList.map((article: ArticleInfo) => (
                  <li key={article.slug}>
                    <Link to={`/articles/${article.slug}`}>{article.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="column is-three-quarters box p-6 m-2">
            <h1 className="title is-2">Jak zaplanować i zrealizować kuchnię na wymiar: Krok po kroku</h1>
            <div className="content">
              <img src="/articles-images/planowanie-kuchni.jpg" alt="Planowanie kuchni na wymiar" />
            </div>

            <p className="block">
              Planowanie nowej kuchni na wymiar to ekscytujące, ale i wymagające zadanie. Wymaga ono starannego przemyślenia wielu aspektów,
              od funkcjonalności po estetykę. W tym artykule przeprowadzimy Cię przez wszystkie kluczowe kroki, które pomogą Ci stworzyć
              kuchnię Twoich marzeń.
            </p>

            <h2 className="title is-3">1. Analiza obecnej kuchni i określenie potrzeb</h2>

            <p className="block">
              Pierwszym krokiem jest dokładna analiza Twojej obecnej kuchni:
            </p>

            <ul className="block">
              <li>Co Ci się w niej podoba, a co chciałbyś zmienić?</li>
              <li>Jakie są jej mocne strony, a jakie słabości?</li>
              <li>Czy układ jest funkcjonalny, czy może wymaga zmian?</li>
              <li>Jakie nowe elementy chciałbyś dodać (np. wyspa kuchenna, więcej miejsca do przechowywania)?</li>
            </ul>

            <p className="block">
              Warto stworzyć listę priorytetów, uwzględniając potrzeby wszystkich domowników.
            </p>

            <h2 className="title is-3">2. Określenie zakresu prac remontowych</h2>

            <p className="block">
              Kolejnym ważnym krokiem jest decyzja o zakresie prac remontowych:
            </p>

            <ul className="block">
              <li>Czy chcesz zachować obecny układ, czy go zmienić?</li>
              <li>Jeśli planujesz zmiany, rozważ konieczność przesunięcia instalacji:</li>
              <ul>
                <li>Elektrycznej (gniazdka, oświetlenie)</li>
                <li>Wodnej (doprowadzenie wody, odpływ)</li>
                <li>Gazowej (jeśli korzystasz z kuchenki gazowej)</li>
              </ul>
              <li>Pamiętaj, że zmiany w instalacjach zwiększą koszty i czas remontu, ale dadzą Ci więcej swobody w projektowaniu.</li>
            </ul>

            <h2 className="title is-3">3. Pomiary i dokumentacja</h2>

            <p className="block">
              Dokładne pomiary są kluczowe dla udanego projektu:
            </p>

            <ul className="block">
              <li>Zmierz długości ścian, wysokość pomieszczenia, położenie okien i drzwi.</li>
              <li>Zanotuj położenie instalacji elektrycznej, wodnej i gazowej.</li>
              <li>Zrób zdjęcia pomieszczenia z różnych perspektyw.</li>
              <li>Narysuj prosty szkic układu pomieszczenia z wymiarami.</li>
            </ul>

            <h2 className="title is-3">4. Określenie budżetu</h2>

            <p className="block">
              Przed rozpoczęciem projektowania, ustal realistyczny budżet:
            </p>

            <ul className="block">
              <li>Uwzględnij koszty mebli, sprzętów AGD, oświetlenia, blatów, materiałów wykończeniowych.</li>
              <li>Jeśli planujesz remont, dodaj koszty prac budowlanych i instalacyjnych.</li>
              <li>Zostaw margines na nieprzewidziane wydatki (ok. 10-15% całości budżetu).</li>
            </ul>

            <h2 className="title is-3">5. Wybór stylu i materiałów</h2>

            <p className="block">
              Zastanów się nad ogólnym stylem i kolorystyką kuchni:
            </p>

            <ul className="block">
              <li>Przejrzyj magazyny wnętrzarskie, strony internetowe i social media w poszukiwaniu inspiracji.</li>
              <li>Wybierz materiały na fronty szafek, blaty, podłogę, ściany.</li>
              <li>Pomyśl o oświetleniu - zarówno funkcjonalnym, jak i dekoracyjnym.</li>
              <li>Pamiętaj o spójności z resztą domu.</li>
            </ul>

            <h2 className="title is-3">6. Projekt i wycena</h2>

            <p className="block">
              Z zebranymi informacjami i pomysłami, udaj się do firmy meblarskiej:
            </p>

            <ul className="block">
              <li>Profesjonalna firma powinna zaoferować bezpłatną wycenę.</li>
              <li>Rozważ skorzystanie z usługi projektowania kuchni - to inwestycja, która może się opłacić.</li>
              <li>Omów swoje potrzeby, pomysły i ograniczenia z projektantem.</li>
              <li>Poproś o wizualizacje 3D projektu.</li>
            </ul>

            <h2 className="title is-3">7. Wybór sprzętu AGD</h2>

            <p className="block">
              Wybierz sprzęty AGD, które pasują do Twojego stylu życia i budżetu:
            </p>

            <ul className="block">
              <li>Zwróć uwagę na klasę energetyczną urządzeń - oszczędniejsze sprzęty to niższe rachunki w przyszłości.</li>
              <li>Pomyśl o innowacyjnych rozwiązaniach, jak np. indukcja, piekarnik parowy czy lodówka side-by-side.</li>
              <li>Upewnij się, że wybrane sprzęty pasują do wymiarów zaplanowanych w projekcie.</li>
            </ul>

            <h2 className="title is-3">8. Wybór wykonawcy</h2>

            <p className="block">
              Starannie wybierz firmę, która zrealizuje Twój projekt:
            </p>

            <ul className="block">
              <li>Porównaj oferty kilku firm.</li>
              <li>Sprawdź opinie i referencje.</li>
              <li>Zapytaj o gwarancję na meble i montaż.</li>
              <li>Ustal dokładny harmonogram prac.</li>
            </ul>

            <h2 className="title is-3">9. Realizacja projektu</h2>

            <p className="block">
              Podczas realizacji projektu:
            </p>

            <ul className="block">
              <li>Bądź w stałym kontakcie z wykonawcą.</li>
              <li>Regularnie monitoruj postępy prac.</li>
              <li>Bądź przygotowany na ewentualne nieoczekiwane wyzwania i zmiany.</li>
            </ul>

            <h2 className="title is-3">10. Odbiór i wykończenie</h2>

            <p className="block">
              Po zakończeniu głównych prac:
            </p>

            <ul className="block">
              <li>Dokładnie sprawdź wszystkie elementy kuchni.</li>
              <li>Upewnij się, że wszystkie sprzęty działają prawidłowo.</li>
              <li>Dodaj ostatnie akcenty dekoracyjne.</li>
            </ul>

            <h2 className="title is-3">Podsumowanie</h2>

            <p className="block">
              Planowanie i realizacja kuchni na wymiar to proces, który wymaga czasu, cierpliwości i uwagi. Jednak rezultat - 
              funkcjonalna, piękna i dostosowana do Twoich potrzeb kuchnia - jest wart wysiłku. Pamiętaj, że kuchnia to serce domu, 
              miejsce, gdzie nie tylko przygotowujesz posiłki, ale też spędzasz czas z rodziną i przyjaciółmi. Dlatego warto 
              poświęcić czas na staranne zaplanowanie każdego detalu.
            </p>

            <p className="block">
              Przy planowaniu kuchni warto również skorzystać z nowoczesnych narzędzi, takich jak kalkulatory online do wyceny 
              mebli kuchennych. Mogą one znacznie ułatwić proces planowania budżetu i pomóc w podejmowaniu decyzji dotyczących 
              wyboru materiałów i rozwiązań.
            </p>
          </div>
        </div>
      </div>
    </article>
  );
};
