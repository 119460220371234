import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { useScrollToTop } from '../../hooks/useScrollToTop';

export const Layout: React.FC = () => {
  useScrollToTop();

  return (
    <>
      <div className="container">
        <Header />
        <div className="main">
          <Outlet />
        </div>
      </div>
      <Footer />
    </>
  );
};